class RGBGlitchShader implements THREE.ShaderMaterialParameters {

    public uniforms = {
        "tDiffuse": {type: "t", value: null},
        "tDiffuseNoise": {type: "t", value: null},
        "fGlobalTime": {type: "f", value: 0.1},
        "fAmplitude": {type: "f", value: 0.00},
        "fSpeed": {type: "f", value: 0.2}
    };

    public vertexShader = `
uniform float fSpeed;
uniform float fGlobalTime;

varying vec2 vUv;
varying float fDisplacement;
varying vec4 vOffsets;

void main() {
    vUv = uv;
    fDisplacement = fGlobalTime * fSpeed;
    float fDisplacementSpeed = fDisplacement * fSpeed * float(250);
    
    vec2 vOffset = vec2(
        sin(fDisplacementSpeed),
        cos(fDisplacementSpeed)
    );
    
    vOffsets = vec4(vOffset.xy, vOffset.xy * -1.0);
    
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`;
    public fragmentShader = `
uniform sampler2D tDiffuse;
uniform sampler2D tDiffuseNoise;
uniform float fAmplitude;

varying vec2 vUv;
varying float fDisplacement;
varying vec4 vOffsets;

void main() {
    vec4 linearShift = texture2D(tDiffuseNoise, vec2(fDisplacement, fDisplacement * 0.2), 0.0);
    
    vec4 shift = vec4(
        pow(linearShift.xyz, vec3(8.0)), 
        linearShift.w
    ) * vec4(vec3(fAmplitude), 1.0);
    
    shift *= 2.0 * shift.w - 1.0;

    // multiply then add = single instruction
    vec4 r = texture2D(tDiffuse, vec2(clamp(shift.x + shift.y, 0.0, 1.0)) * vOffsets.yw + vUv, 0.0);
    vec4 g = texture2D(tDiffuse, vec2(clamp(shift.y + shift.z, 0.0, 1.0)) * vOffsets.xy + vUv, 0.0);
    vec4 b = texture2D(tDiffuse, vec2(clamp(shift.z + shift.x, 0.0, 1.0)) * vOffsets.wz + vUv, 0.0);
    
    gl_FragColor = vec4(
        r.x,
        g.y,
        b.z,
        clamp(r.w + g.w + b.w, 0.0, 1.0)
    );
}
`;
}

/// <reference path='./Rect.ts'/>
/// <reference path='./LineTextureRenderer.ts'/>

type UV = THREE.Vector2;
type TextureUV = Array<UV>;

class TextureAtlas {
    private renderedStrings:{[text:string]:Rect} = {};
    private renderer:JQuery;

    private rowHeight:number = -1;
    private rowWidth:number;
    private rows:Row[] = [];
    private size:number;

    private atlases:Atlas[] = [];
    private canvasWidth:number;
    private canvasHeight:number;

    private padding:number;

    /**
     * @param size The power of 2 to use for the size of the canvas
     * @param container
     */
    constructor(size:number, container:JQuery) {
        this.size = size;
        this.renderer = container;
        this.rowWidth = 2 ** size;
        this.canvasWidth = 2 ** size;
        this.canvasHeight = 2 ** size;

        this.addAtlas();
    }

    private addAtlas() {
        const canvas = document.createElement('canvas');
        canvas.width = this.canvasWidth;
        canvas.height = this.canvasHeight;

        const ctx = canvas.getContext('2d');

        const texture = new THREE.CanvasTexture(
            canvas,
            THREE.Texture.DEFAULT_MAPPING,
            THREE.ClampToEdgeWrapping,
            THREE.ClampToEdgeWrapping,
            THREE.LinearFilter,
            THREE.LinearFilter,
            THREE.RGBAFormat
        );
        texture.flipY = false;
        texture.needsUpdate = true;
        texture.generateMipmaps = false;

        this.atlases.push({
            canvas: canvas,
            ctx: ctx,
            rows: [],
            texture: texture,
        });

        this.renderer.empty().append(canvas);
    }

    public changeRowHeight(height:number, padding:number) {
        this.rowHeight = height;
        this.padding = padding;
    }

    public paintText(text:string, w:number, h:number, face:GlyphHeight, extra?:string[]):Rect {
        let cacheKey = `${text}-${w}-${h}`;
        if (extra && extra.length !== 0) {
            cacheKey += `-${extra.join('-')}`;
        }
        if (this.renderedStrings[cacheKey]) {
            return this.renderedStrings[cacheKey];
        }

        const block = this.findBlockInRow(w, h);

        let brushOffset = block.exteriorY1;

        this.atlases[0].ctx.textBaseline = "hanging";
        this.atlases[0].ctx.fillText(text, block.interiorX1, brushOffset + 5);

        // this is useful when debugging
        // this.atlases[0].ctx.strokeStyle = 'black';
        // this.atlases[0].ctx.strokeRect(block.exteriorX1, block.exteriorY1, block.exteriorWidth, block.exteriorHeight);
        // this.atlases[0].ctx.strokeRect(block.interiorX1, block.interiorY1, block.interiorWidth, block.interiorHeight);

        this.atlases[0].texture.needsUpdate = true;
        this.renderedStrings[cacheKey] = block;
        return block;
    }

    public measureText(text:string):TextMetrics {
        return this.atlases[0].ctx.measureText(text);
    }

    public setFont(face:string, size:number, extra?:string[]) {
        let font = `${size}px ${face}`;
        if (extra && extra.length !== 0) {
            font = extra.join(' ') + ` ${font}`;
        }
        this.atlases[0].ctx.font = font;
        this.atlases[0].ctx.fillStyle = '#FFF';
    }

    public getTexture():THREE.Texture {
        return this.atlases[0].texture;
    }

    public getTextureUV(d:Rect):TextureUV {
        const ret = [
            this.getUVCoordinate(d.exteriorX1, d.exteriorY1),
            this.getUVCoordinate(d.exteriorX1, d.exteriorY2),
            this.getUVCoordinate(d.exteriorX2, d.exteriorY2),
            this.getUVCoordinate(d.exteriorX2, d.exteriorY1)
        ];

        ret.forEach((i:THREE.Vector2) => {
            if (i.x > 1 || i.y > 1) {
                console.warn('UV 0.0-1.0 range exceeded', i.x, i.y);
            }
        });

        return ret;
    }

    private findBlockInRow(w:number, h:number):Rect {
        const rowIdx = this.findRowWithVacancy(w + this.padding);
        const row = this.atlases[0].rows[rowIdx];

        const rect = new Rect(row.used, rowIdx * (this.rowHeight + this.padding), w, h, this.padding);
        row.allocate(rect.exteriorWidth);
        return rect;
    }

    private findRowWithVacancy(required:number):number {
        let atlas = this.atlases[0];

        let candidateRows = atlas.rows.filter(r => r.free > required);

        if (candidateRows.length === 0) {
            let row = new Row(this.rowWidth);
            atlas.rows.push(row);
            return atlas.rows.indexOf(row);
        } else {
            let row = candidateRows[0];
            if (!row) {
                row = new Row(this.rowWidth);
                atlas.rows.push(row);
            }
            return atlas.rows.indexOf(row);
        }
    }

    /**
     * (0,0) is bottom-left
     *
     * @param x
     * @param y
     * @return UV
     */
    private getUVCoordinate(x:number, y:number):UV {
        return new THREE.Vector2(
            x / this.canvasWidth,
            y / this.canvasHeight
        );
    }
}

class Row {
    private size:number;
    public used:number = 0;

    constructor(size:number) {
        this.size = size;
    }

    public allocate(length:number) {
        this.used += length + 2;
    }

    get free() {
        return this.size - this.used;
    }
}

interface Atlas {
    canvas:HTMLCanvasElement;
    ctx:CanvasRenderingContext2D;
    texture:THREE.Texture;
    rows:Row[];
}

/// <reference path="./PhotoCard.ts" />

class InstallationTitleSequence extends BaseTitleSequence {

    constructor(renderer:LineTextureRenderer, titleGenerator:TitleGenerator) {

        super(renderer, titleGenerator);

        this.shift(false);

        EventBus.addEventListener(PhotoCard.ADD_TO_TITLE, () => this.shift(MultiTouchInterfaceScene.RESOLVING), this);
    }
}

/**
 * Main static class, holds all global config variables and functions for all views and components.
 */
class Main {

    /**
     * Public config variables passed in from the php config
     */
    static config:any;

    /**
     * Browser specific css style declaration to use for transforming objects.
     */
    static xform:string;

    /**
     * Browser specific css style declaration to use for transitioning objects.
     */
    static transition:string;

    /**
     * JQuery object containing all of the templates to be used for reusable components
     */
    static templates:JQuery;

    static sWidth:number;

    static sHeight:number;

    static socket = '';

    static room:string = 'fpo';

    static mobile:boolean = false;

    /**
     * Creates a new Main instance, sets the templates for all views and components to use, creates a global socket connection, and gets the saved state from a cookie.
     *
     * @param config    Configuration variables passed in from config.php.
     */
    constructor(config) {

        //set the php config variables
        Main.config = config;

        this.setPrefixes();

        Main.templates = $("#templates");

        $(window).bind('resize orientationchange', ()=>this.resize());
        this.resize();

        Main.socket = `${window.location.protocol}//${window.location.hostname}:8080`;

        var md = new MobileDetect(window.navigator.userAgent);
        Main.mobile = (md.mobile() || md.tablet()) ? true : false;
    }

    private resize() {

        Main.sWidth = $(window).width();
        Main.sHeight = $(window).height();
    }

    /**
     * Sets Main.xform and Main.transition by detecting which prefix exists on the current browser.
     */
    private setPrefixes():void {

        var view = document.createElement('div');
        var prefixes = ['webkit', 'Moz', 'O', 'ms'];
        for (var i = 0; i < prefixes.length; i++) {
            var prefix = prefixes[i];
            var e = prefix + 'Transform';
            var t = prefix + 'Transition';

            if (typeof view.style[e] !== 'undefined') {
                Main.xform = e;
                Main.transition = t;
            }
        }
        view = null;
    }
}

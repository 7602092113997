class InstallationStage extends Stage {

    public socket:SocketIOClient.Socket;

    constructor(params?) {

        super(params);

        this.socket = io(Main.socket);
        this.socket.on('connect', (evt)=>this.connectedToSocket(evt));
        this.socket.on('error', (evt)=>this.connectionError(evt));
        this.socket.on('reconnect', (evt)=>this.connectedToSocket(evt));
        this.socket.on('disconnect', (evt)=>this.disconnectedFromSocket(evt));
        this.socket.on('reset', (evt)=>this.reset(evt));

        $('.header, .footer').hide();
    }

    public reset(evt) {
        location.reload();
    }

    private connectionError(evt) {

        console.log("Socket Connection Error");
        console.log(evt);

    }

    private connectedToSocket(evt) {

        console.log("Socket Connection Successful");
        this.socket.emit('joinRoom', {'roomName': Main.room});
    }

    private disconnectedFromSocket(evt) {

        console.log("Disconnected from Socket");
        console.log(evt);
    }
}

enum RenderOrder {
    Default,
    CurrentPhotoCard,
    ParticleTrails,
    BackgroundPhotoCards
}

/// <reference path='./PlaneImage.ts'/>
/// <reference path='./render/EffectComposer.ts'/>
/// <reference path='./render/RenderPass.ts'/>

class Stage {
    public uuid:string = Utils.generateUUID();

    protected clock:THREE.Clock;
    protected container:JQuery;
    protected currentScene:NickelScene;
    protected textureLoader:THREE.TextureLoader;
    protected planeImageFactory:PlaneImageFactory;
    protected params:StageParams;
    protected mousePosition:THREE.Vector3 = new THREE.Vector3(0, 0, 0.5);

    private gui:dat.GUI;
    private stats:Stats;
    private cameraTravel:THREE.Vector2 = new THREE.Vector2(10, 2);
    private cameraRatio:THREE.Vector2 = new THREE.Vector2(0, 0);

    private renderPass:RenderPass;
    private composer:EffectComposer;

    private enableRenderLoop:boolean = false;

    public static events:string[] = [
        'mousedown',
        'touchstart',
        'mousemove',
        'touchmove',
        'mouseup',
        'touchend'
    ];

    public static RENDERER:THREE.WebGLRenderer;

    public static CAMERA:THREE.PerspectiveCamera;

    public static NEXT_SCENE:string = 'next_scene';

    constructor(params?:StageParams) {
        this.params = params;
        Stage.CAMERA = new THREE.PerspectiveCamera(45, params.width / params.height, 1, 1100);

        this.container = params.container;
        this.textureLoader = new THREE.TextureLoader();
        this.textureLoader.crossOrigin = 'anonymous';
        const imageLoader = new THREE.ImageLoader();
        imageLoader.crossOrigin = 'anonymous';
        this.planeImageFactory = new PlaneImageFactory(imageLoader);

        this.clock = new THREE.Clock(true);

        Stage.RENDERER = new THREE.WebGLRenderer({
            precision: 'mediump',
            alpha: true
        });
        Stage.RENDERER.setClearColor(0x000000, 0);
        Stage.RENDERER.setSize(params.width, params.height);

        this.container.append(Stage.RENDERER.domElement);

        if (params.enableStats) {
            const stats = new Stats();
            stats.setMode(0);

            stats.domElement.style.position = 'absolute';
            stats.domElement.style.left = '0px';
            stats.domElement.style.top = '0px';
            stats.domElement.style.zIndex = '10';

            this.stats = stats;
            this.container.append(this.stats.domElement);
        }

        if (params.enableGui) {
            this.gui = new dat.GUI({closed: true});
        }

        let canvas = $(Stage.RENDERER.domElement);

        Stage.events.forEach(
            (e:string) =>
                canvas.on(e, (event) => {
                    if (this.currentScene) {
                        this.currentScene.dispatchEvent({type: e, message: event});
                    }
                }));


        this.renderPass = new RenderPass(this.currentScene, Stage.CAMERA, undefined, new THREE.Color(0x000000), 1);
        // const pixelRatio = Stage.RENDERER.getPixelRatio();
        const pixelRatio = window.devicePixelRatio;

        const width = Math.floor(Stage.RENDERER.context.canvas.width / pixelRatio) || 1;
        const height = Math.floor(Stage.RENDERER.context.canvas.height / pixelRatio) || 1;
        const renderTarget = new THREE.WebGLRenderTarget(width, height, {
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            format: THREE.RGBAFormat,
            stencilBuffer: false
        });


        this.composer = new EffectComposer(Stage.RENDERER, renderTarget);

        this.composer.addPass(this.renderPass);

        window.addEventListener('resize', () => this.resize());
        this.resize();

        TweenMax.ticker.addEventListener('tick', () => this.renderLoop());
    }

    public resize() {
        this.params.width = window.innerWidth;
        this.params.height = window.innerHeight;
        Stage.RENDERER.setSize(window.innerWidth, window.innerHeight);
        this.composer.setSize(window.innerWidth, window.innerHeight);
        Stage.CAMERA.aspect = window.innerWidth / window.innerHeight;
        Stage.CAMERA.updateProjectionMatrix();
    }

    public run():void {
        this.enableRenderLoop = true;
        Stage.RENDERER.domElement.style.display = "block";
    }

    public pause():void {
        this.enableRenderLoop = false;
        Stage.RENDERER.domElement.style.display = "none";
    }

    protected changeScene(scene:NickelScene) {
        if (this.currentScene) {
            this.currentScene.children.forEach(c => this.currentScene.remove(c));
        }
        this.currentScene = scene;
        this.renderPass.scene = scene;
        this.composer.passes = [];
        this.composer.addPass(this.renderPass);
        this.currentScene.getPostProcessingPasses().forEach((p) => this.composer.addPass(p));

        const copyPass = new ShaderPass(new CopyShader());
        copyPass.renderToScreen = true;
        this.composer.addPass(copyPass);
    }

    protected enableMouseCamera():void {
        let newPos = new THREE.Vector2(0, 0);
        const cameraTarget = new THREE.Vector3(0, 0, -500);

        const updatePosition =(x:number, y:number) => {
            this.cameraRatio.x = x/ this.params.width;
            this.cameraRatio.y = y/ this.params.height;

            this.mousePosition.x = this.cameraRatio.x * 2 - 1;
            this.mousePosition.y = this.cameraRatio.y * -2 + 1;
            this.mousePosition.z = 0.5;

            newPos.setX(this.cameraTravel.x * this.cameraRatio.x * 2 - this.cameraTravel.x);
            newPos.setY(this.cameraTravel.y * this.cameraRatio.y * 2 - this.cameraTravel.y);

            Stage.CAMERA.position.x = newPos.x;
            Stage.CAMERA.position.y = newPos.y;

            Stage.CAMERA.lookAt(cameraTarget);
            Stage.CAMERA.updateMatrixWorld(true);
        };

        let jQ = $(Stage.RENDERER.domElement);

        jQ.on('mousemove', (j: BaseJQueryEventObject)=>{
            let e = <MouseEvent>j.originalEvent;
            updatePosition(e.clientX, e.clientY);
        });
        jQ.on('touchstart', (j: BaseJQueryEventObject)=>{
            let e = <TouchEvent>j.originalEvent;
            updatePosition(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
        });
        jQ.on('touchmove', (j: BaseJQueryEventObject)=>{
            let e = <TouchEvent>j.originalEvent;
            updatePosition(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
        });
        ['mousemove', 'touchstart', 'touchmove'].forEach(e => {
            let evtHandlers = $._data(Stage.RENDERER.domElement, 'events')[e];
            let lastHandler = evtHandlers.pop();
            evtHandlers.splice(0, 0, lastHandler);
        });
    }

    protected renderLoop() {
        if (this.enableRenderLoop) {
            this.stageTick();
            if (this.stats) {
                this.stats.update();
            }

            this.composer.render();
        }
    }

    protected stageTick():void {
        if (this.currentScene) {
            this.currentScene.dispatchEvent({type: NickelScene.TICK_EVENT, message: {dt: this.clock.getDelta()}});
        }
    }

    protected calculateCameraView(zDepth:number):CameraView {
        const view = Utils.calcCameraView(zDepth);

        window.addEventListener('resize', () => {
            const newView = Utils.calcCameraView(zDepth);
            view.width = newView.width;
            view.height = newView.height;
        });

        return view;
    }
}

interface StageParams {
    container:JQuery;
    enableStats?:boolean;
    enableGui?:boolean;
    width?:number;
    height?:number;
}

/// <reference path='../scaffolding/Component.ts'/>
/// <reference path='../scaffolding/EventBus.ts'/>
/// <reference path='./Footer.ts'/>
/// <reference path='./Header.ts'/>

/**
 * Simple component for loading and playing an video file with a <video> tag.
 */
class VideoPlayer extends Component {

    /**
     * The video Node to play the video with.
     */
    private videoNode:any;

    /**
     * JQuery reference to the video node.
     */
    private $videoNode:any;

    /**
     * div holding the spinner for this video player.
     */
    private loader:any;

    /**
     * The url we're currently playing through the video node.
     */
    private activeSrc:string = "";

    /**
     * If we should play the video as soon as it's loaded.
     */
    public autoplay:boolean = false;

    /**
     * If the video is playing right now or not.
     */
    public isPlaying:boolean = false;


    public isMuted: boolean = false;

    /**
     * Whether or not we should be checking the progress of the video as it plays. Disabled by default for performace reasons.
     */
    public checkProgress:boolean = false;

    /**
     * What time we should start the video at.
     */

    public player:any;
    private currentTimeInterval:number;

    public videoLink:string = "";
    public videoPoster:string;
    public initialized:boolean = false;

    private gaFired: number = 0;

    private titleDeleted: boolean = false;
    private videoId: string;

    private prevPercent:any;

    public isBuffering:boolean = false;

    /**
     * Event Dispatched once the video node begins to play.
     */
    public static PLAY:string = "playvideo";

    /**
     * Event Dispatched once the video node reaches the end.
     */
    public static VIDEO_END:string = "videoend";

    /**
     * Event Dispatched on an interval as the video plays.
     */
    public static PROGRESS:string = "progress";
    public static LOAD_VIDEO:string = "globalloadvideo";
    public static SHOW_PLAYER:string = "showglobalplayer";
    public static HIDE_PLAYER:string = "hideglobalplayer";

    /**
     * Stores the global vars, sets the references to the video node, and loader.
     * @param container     A jQuery object containing the parent div for this component.
     * @param data          The JSON data unique to this component.
     * @param delegate      The Class that created this instance.
     */
    constructor(container:JQuery, data:any, delegate:any) {

        super(container, data, delegate);
        this.setContent(Main.templates.find('.videoPlayer').clone());

        EventBus.addEventListener(VideoPlayer.LOAD_VIDEO, $.proxy(this.loadVideo, this), this);
        EventBus.addEventListener(VideoPlayer.SHOW_PLAYER, $.proxy(this.showMe, this), this);
        EventBus.addEventListener(VideoPlayer.HIDE_PLAYER, $.proxy(this.hideMe, this), this);
        EventBus.addEventListener(Footer.MUTE_VIDEO, $.proxy(this.toggleMute, this), this);

        $(window).bind('resize', $.proxy(this.resize, this));
        this.resize();

        // this.content.bind('click touchend', (e)=>this.togglePlay(e));

        this.loader = this.content.find('.loader');
    }

    private togglePlay(e) {

        if (e.originalEvent.shiftKey) {
            this.skipToEnd();
            return;
        }

        if (this.player.paused()) {
            this.player.play();
        } else {
            this.player.pause();
        }
    }

    private closeClicked() {

        this.hideMe();
    }

    public setContent(v:JQuery):void {

        this.content = v;
        this.container.append(this.content);
    }

    private onPlayerStateChange(e) {

    }

    public init() {
        this.initialized = true;

        this.content.append("<video id = 'player' class = 'video-js vjs-default-skin'></video>");
        this.player = videojs('player', {
            autoplay: this.autoplay,
            controls: false,
            preload: 'auto'
        });


        $('.vjs-control-bar').hide(); //TODO, make this better

        this.setSrc();
        this.resize();

        if (this.autoplay) {
            this.player.show();
        }

        this.player.on('ended', ()=>this.videoEnded());
    }

    public setSrc() {

        this.player.src(this.videoLink);
        this.player.load();

        this.player.hide();
    }

    private skipToEnd() {

        let time = this.player.duration() - 1;
        this.player.currentTime(time);
    }

    /**
     * Loads a video through the video node, binds the canplaythrough event.
     * @data        The url and poster frame for the video we want to load.
     */
    public loadVideo(data:VideoParams) {
        this.videoId = data.expId;
        this.videoLink = this.getVideoLink(data.src);
        if (data.poster) {
            this.videoPoster = data.poster;
        }

        if (!this.initialized) {
            this.init();
        } else {
            this.setSrc();
        }
    }

    public getVideoLink(path) {
        if (path.indexOf("http://") == 0 || path.indexOf("https://") == 0 || !Main.config['mediaBaseUrl']) {
            return path;
        } else {
            return Main.config['mediaBaseUrl'] + '/' + path;
        }
    }

    /**
     * Plays the video node, if we're checking progress, sets the checkTimeInt.
     */
    public playVideo():void {

        EventBus.dispatchEvent('VIDEO_START');
        this.player.show();
        this.player.play();

        // this.content.find('.loader').show();
   

        if(Main.mobile){
            $('.play').bind('webkitendfullscreen', ()=>this.videoEnded(), false);
        }
        if(this.isMuted){
            this.player.muted(true)
        }
        this.isPlaying = true;
        clearInterval(this.currentTimeInterval);
        this.currentTimeInterval = setInterval(()=> {
            let perc = this.player.currentTime() / this.player.duration()
            let percentFire = 0;
            if (perc >= 0.75 && this.gaFired == 2) {
                this.gaFired = 3;
                gaTrackEvent('75% completion', 'film_playback');

            } else if (perc >= 0.50 && this.gaFired == 1) {
                this.gaFired = 2;
                gaTrackEvent('50% completion', 'film_playback');

            } else if (perc >= 0.25 && this.gaFired == 0) {
                this.gaFired = 1;
                gaTrackEvent('25% completion', 'film_playback');
            } else if(perc >=0.030 && !this.titleDeleted){
                
                this.titleDeleted = true;
                this.delegate.api.moveExperienceToGraveyard(this.videoId);
                gaTrackEvent('title seen', 'film_playback');
            }
            if(this.prevPercent == perc && !this.isBuffering){
                this.buffering();
            } else if(this.prevPercent != perc && this.isBuffering) {
                this.bufferingOver();
            }

            this.prevPercent = perc;
        }, 150);
        this.dispatch(VideoPlayer.PLAY);
    }


    public buffering(){
        this.isBuffering = true;
        this.content.find('.loader').show();
        // this.player.one('progress', ()=>this.bufferingOver())
    }

    public bufferingOver() {
        if(this.isBuffering){
            this.isBuffering = false;
            this.content.find('.loader').hide();
        }
    }

    /**
     * Pauses the video node.
     */
    public pauseVideo() {
        this.isPlaying = false;
        clearInterval(this.currentTimeInterval);
        this.player.pause();
    }

    /**
     * Pauses the video and hides the component.
     */
    public hideMe() {

        super.hideMe();
        
        EventBus.dispatchEvent('SHOW_HEADER')
        if (this.player) this.pauseVideo();
    }

    public showMe() {

        super.showMe();
    }

    private preventScroll(e) {
        e.preventDefault();
    }

    public resize() {

        var h = window.innerHeight;
        var w = window.innerWidth;

        this.content.css({
            'top': 0,
            'height': h,
            'width': w
        });

        var pos = Utils.fitToContainer({
            'containerWidth': w,
            'containerHeight': h,
            'contentWidth': 1280,
            'contentHeight': 720,
            'vAlign': 'center',
            'hAlign': 'center',
            'scaleMode': 'proportionalInside'
        });

        if (this.player) {
            $("#player").css(pos);
        }
    }

    private videoEnded() {
        this.killMe();
        EventBus.dispatchEvent("VIDEO_OVER")
    }

    private toggleMute(){
        if(this.isMuted){
            this.isMuted = false;
        } else {
            this.isMuted = true;
        }


        if (this.isPlaying) {
            this.player.muted(this.isMuted)
        }
    }
}

interface VideoParams {
    src:string;
    poster?:string;
    expId?:string;
}

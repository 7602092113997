class VideoBackground extends THREE.Mesh {
    protected vEle:HTMLVideoElement;

    private videoPool:string[];
    private tickFn:() => void;
    private vCtx:CanvasRenderingContext2D;
    private vTex:THREE.Texture;
    private realParent:THREE.Object3D;

    constructor(cameraHeight:number, videoPool:string[]) {
        const videoElement:HTMLVideoElement = document.createElement('video');
        videoElement.crossOrigin = 'anonymous';
        const videoImage = document.createElement('canvas');
        videoImage.width = 176;
        videoImage.height = 80;

        const videoImageContext = videoImage.getContext('2d');
        videoImageContext.fillStyle = '#000000';
        videoImageContext.fillRect(0, 0, videoImage.width, videoImage.height);

        const videoTexture = new THREE.Texture(
            videoImage,
            THREE.Texture.DEFAULT_MAPPING,
            THREE.ClampToEdgeWrapping,
            THREE.ClampToEdgeWrapping,
            THREE.LinearFilter,
            THREE.LinearFilter
        );
        videoTexture.minFilter = THREE.LinearFilter;
        videoTexture.magFilter = THREE.LinearFilter;

        const movieMaterial = new THREE.MeshBasicMaterial({map: videoTexture});

        const backgroundHeight = cameraHeight * 1.1;
        const backgroundRatio = videoImage.width / videoImage.height;
        const backgroundWidth = backgroundHeight * backgroundRatio;
        const geometry = new THREE.PlaneGeometry(backgroundWidth, backgroundHeight);

        // TODO: File bug with JetBrains about this
        //noinspection TypeScriptValidateTypes
        super(geometry, movieMaterial);
        this.vEle = videoElement;
        this.vCtx = videoImageContext;
        this.vTex = videoTexture;
        this.videoPool = videoPool;

        videoElement.src = this.getRandomVideoUrl();
        videoElement.loop = true;
        videoElement.load();
        videoElement.play();
        videoElement.volume = 0;

        this.tickFn = () => {
            if (this.vEle.readyState === this.vEle.HAVE_ENOUGH_DATA) {
                this.vCtx.drawImage(this.vEle, 0, 0);
                if (this.vTex) {
                    this.vTex.needsUpdate = true;
                }
            }
        };

        this.addEventListener('added', () => {
            this.realParent = this.parent;
            this.realParent.addEventListener(NickelScene.TICK_EVENT, this.tickFn);
        });
        this.addEventListener('removed', () => {
            this.vEle.pause();
            this.realParent.removeEventListener(NickelScene.TICK_EVENT, this.tickFn);
        });
    }

    protected getRandomVideoUrl():string {
        let prefix = CDN_GLOBAL_PREFIX;
        if(!Main.mobile){            
            let is_safari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
            if(is_safari){
                prefix = "";
            }
        }
        return prefix + '/vid/backgrounds/' + _.sample(this.videoPool);
    }
}

/// <reference path='../../scaffolding/Component.ts'/>

class GraveyardTitle extends Component {

    private titleData: any;
    private jitterStrength:number = 500;
    private infoOpen:boolean = false;
    private info:Component;
    private removeFunc:any;
    private animateDelay:number = 7.6;

    public static OPEN_TITLE:string = "opentitle";
    public static CLOSE_TITLE:string = "closetitle";

    constructor(container:JQuery, data:any, delegate:any) {
        super(container, data, delegate);
        this.setContent(Main.templates.find('.graveyardTitle').clone());
        this.titleData = data;

        this.content.find('.text').bind('click', (e)=>this.clicked(e));
        this.content.find('.text').append(this.data.title);

        if (!Main.mobile) {
            let x = _.random(-this.jitterStrength, this.jitterStrength) / 100;
            let y = _.random(-this.jitterStrength, this.jitterStrength) / 100;
            let z = _.random(-this.jitterStrength, this.jitterStrength) / 100;

            let xform = 'translate3d(' + x + 'px,' + y + 'px,' + z + 'px)';
            this.content.find('.text')[0].style[Main.xform] = xform;
        }
        this.removeFunc = ()=>this.clearInfo();
        if(this.data.animateIn){
            this.content.addClass('hidden');
            TweenMax.delayedCall(this.animateDelay, ()=>{
                if (this.content) {
                    this.content.removeClass('hidden');
                    this.dispatch(GraveyardTitle.OPEN_TITLE, this);
                }
            });
        }
    }

    private clicked(e){

        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        if(this.infoOpen){

            this.dispatch(GraveyardTitle.CLOSE_TITLE, this);
        }else{

            this.dispatch(GraveyardTitle.OPEN_TITLE, this);
        }
    }

    private clearInfo(){

        this.info.killMe();
        this.info = undefined;
    }

    public closeInfo(){

        TweenMax.killDelayedCallsTo(this.removeFunc);
        if (this.data.animateIn){
            this.content.removeClass('open');
        } else {
            this.content.removeClass('openSmall');
        }
        TweenMax.delayedCall(1, this.removeFunc);
        this.infoOpen = false;
    }

    public openInfo() {

        TweenMax.killDelayedCallsTo(this.removeFunc);
        if (this.data.animateIn){
            this.content.addClass('open');
        } else {
            this.content.addClass('openSmall');
        }
        if(!this.info){
            this.info = new Info(this.content, this.data, this);
        }
        this.infoOpen = true;
    }
}

class Info extends Component{

    constructor(container, data, del){

        super(container, data, del);

        this.setContent(Main.templates.find('.info').clone());

        this.content.find(".tell").bind('click', (e)=>this.shareTwitter(e));
        this.content.find(".skip").bind('click', (e)=>{
            this.delegate.clicked(e);
        });
        if (!this.data.animateIn){
            this.content.find('.data').hide();
        }
        this.setText();
    }

    private setText(){

        this.content.find('.movietitle').html(this.data.title);

        if(this.data.city !== null && this.data.country !== null){
            this.content.find('.location').html(this.data.city + ", " + this.data.country);
        }

        this.getTime();
    }

    private shareTwitter(e) {

        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        
        let tweet = "I Just watched [title] and you can't. #seances #threestars";
        tweet = tweet.replace('[title]', this.data.title);

        Share.onTwitter(Main.config.shareUrl, tweet);

        gaTrackEvent('share_twitter', 'click');
    }

    public getTime(){
        let month_names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        let date = new Date(this.data.time_watched*1000);
        let month = date.getMonth();
        let day = date.getDate();
        let year = date.getFullYear();
        let hours = date.getHours();
        let minutes:any = date.getMinutes();
        let amPm = 'am'
        if(hours > 12){
            amPm = 'pm'
            hours = hours-12
        } else if(hours == 0){
            amPm = 'pm'
            hours = 12;
        }

        if(minutes<10){
            minutes = "0" + minutes;
        }

        this.content.find('.date').html(month_names[month] + ' ' + day + ', ' + year);
        this.content.find('.lost').html('Lost at ' + hours + ":"+minutes+ ' '+amPm);
    }
}

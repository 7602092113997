/// <reference path='./ShaderPass.ts'/>
/// <reference path='./CopyShader.ts'/>
/// <reference path='./MaskPass.ts'/>

class EffectComposer {
    public passes:EffectPass[] = [];

    private renderer:THREE.WebGLRenderer;
    private renderTarget1:THREE.WebGLRenderTarget;
    private renderTarget2:THREE.WebGLRenderTarget;
    private writeBuffer:THREE.WebGLRenderTarget;
    private readBuffer:THREE.WebGLRenderTarget;
    private copyPass:ShaderPass = new ShaderPass(new CopyShader());

    constructor(renderer:THREE.WebGLRenderer,
                renderTarget?:THREE.WebGLRenderTarget) {

        this.renderer = renderer;

        if (renderTarget === undefined) {

            let pixelRatio = renderer.getPixelRatio();

            let width = Math.floor(renderer.context.canvas.width / pixelRatio) || 1;
            let height = Math.floor(renderer.context.canvas.height / pixelRatio) || 1;
            let parameters = {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBFormat,
                stencilBuffer: false
            };

            renderTarget = new THREE.WebGLRenderTarget(width, height, parameters);

        }

        this.renderTarget1 = renderTarget;
        this.renderTarget2 = renderTarget.clone();

        this.writeBuffer = this.renderTarget1;
        this.readBuffer = this.renderTarget2;
    }

    public swapBuffers() {
        let tmp = this.readBuffer;
        this.readBuffer = this.writeBuffer;
        this.writeBuffer = tmp;
    }

    public addPass(pass:EffectPass) {
        this.passes.push(pass);
    }

    public insertPass(pass:EffectPass, index:number) {
        this.passes.splice(index, 0, pass);
    }

    public render(delta?:number) {

        this.writeBuffer = this.renderTarget1;
        this.readBuffer = this.renderTarget2;

        let maskActive = false;

        let pass, il = this.passes.length;

        for (let i = 0; i < il; i++) {

            pass = this.passes[i];

            if (!pass.enabled) continue;

            pass.render(this.renderer, this.writeBuffer, this.readBuffer, delta, maskActive);

            if (pass.needsSwap) {

                if (maskActive) {

                    let context = this.renderer.context;

                    context.stencilFunc(context.NOTEQUAL, 1, 0xffffffff);

                    this.copyPass.render(this.renderer, this.writeBuffer, this.readBuffer, delta);

                    context.stencilFunc(context.EQUAL, 1, 0xffffffff);

                }

                this.swapBuffers();

            }

            if (pass instanceof MaskPass) {

                maskActive = true;

            } else if (pass instanceof ClearMaskPass) {

                maskActive = false;

            }

        }

    }

    public reset(renderTarget:THREE.WebGLRenderTarget) {

        if (renderTarget === undefined) {
            renderTarget = this.renderTarget1.clone();
            let pixelRatio = this.renderer.getPixelRatio();

            renderTarget.setSize(
                Math.floor(this.renderer.context.canvas.width / pixelRatio),
                Math.floor(this.renderer.context.canvas.height / pixelRatio)
            );
        }

        this.renderTarget1.dispose();
        this.renderTarget1 = renderTarget;
        this.renderTarget2.dispose();
        this.renderTarget2 = renderTarget.clone();

        this.writeBuffer = this.renderTarget1;
        this.readBuffer = this.renderTarget2;

    }

    public setSize(width:number, height:number) {
        this.renderTarget1.setSize(width, height);
        this.renderTarget2.setSize(width, height);
    }
}

interface EffectPass {
    enabled:boolean;
    needsSwap:boolean;

    render(renderer:THREE.WebGLRenderer,
           writeBuffer:THREE.WebGLRenderTarget,
           readBuffer:THREE.WebGLRenderTarget,
           delta:number,
           maskActive:boolean);
}

/// <reference path='../../scaffolding/three/Stage.ts'/>
/// <reference path='../../scaffolding/three/Background.ts'/>
/// <reference path='../../scaffolding/three/VideoBackground.ts'/>
/// <reference path='../../scaffolding/three/ImageBackground.ts'/>
/// <reference path='../../scaffolding/BandwidthDetector.ts'/>

class SeancesStage extends Stage {

    protected api:SeancesApi;
    protected backgroundDims:CameraView;
    private player:VideoPlayer;
    private vidData:any;
    private playingInterval:number;
    private cont:JQuery;

    constructor(api:SeancesApi, params?:StageParams, skipIntro?:boolean, graveyard?:boolean) {
        super(params);

        this.api = api;
        this.cont = $("#container");
        this.backgroundDims = this.calculateCameraView(-1000);

        if (graveyard) {
            this.startGraveyardScene();
        } else if (!skipIntro) {
            this.startIntroduction();
        } else {
            this.startIntroScene().then(e => this.run());
        }
    }

    public startIntroduction() {
        const intro = new IntroSceneNoThree(this.cont, {}, this);
        EventBus.addEventListener("INTRO_OVER", () => {
            intro.killMe();
            this.startIntroScene().then(e => this.run());
        }, this);
    }

    public startIntroScene():Promise<void> {
        this.enableMouseCamera();
        const cursor = new ProgressCircle(Stage.CAMERA);
        cursor.addEventListener('mousemove', (e) => {
            cursor.updatePosition(this.mousePosition)
        });

        cursor.addEventListener('touchmove', (e) => {
            cursor.updatePosition(this.mousePosition)
        });
        cursor.addEventListener('touchstart', (e) => {
            cursor.updatePosition(this.mousePosition)
        });

        const titleDims = this.calculateCameraView(-300);

        return this.api.getExperience()
            .then((e) => e['data'])
            .then((d) => {
                this.changeScene(new SeancesIntroScene(
                    cursor,
                    this.textureLoader,
                    d,
                    this.backgroundDims,
                    titleDims,
                    this.planeImageFactory
                ));

                this.vidData = d;

                this.getUrl();
            });
    }

    private getUrl(){

        let url;
        let vd;

        if(Main.mobile){
            url = (this.vidData['m3u8_url']) ? this.vidData['m3u8_url'] : this.vidData['mp4_url'];
            vd = {
                src: url,
                expId: <Uuid>this.vidData['id']
            };

            this.loadVideoPlayer(vd);

            this.currentScene.addEventListener(Stage.NEXT_SCENE, (e) => this.startVideoPlayer(vd));
        }else{
            let band = new BandwidthDetector(); 
            EventBus.addEventListener(BandwidthDetector.GOT_SPEED, (d)=>this.gotBandwidth(d), this);
            band.getSpeed();
        }
    }

    private gotBandwidth(bandwidth){

        let vd:VideoData = {
            expId:this.vidData['id'],
            src:''
        };

        if(bandwidth == BandwidthDetector.LOW){
            vd.src = this.vidData['mp4_url_480'];
        }else if(bandwidth == BandwidthDetector.MEDIUM){
            vd.src = this.vidData['mp4_url_720'];
        }else if(bandwidth == BandwidthDetector.HIGH){
            vd.src = this.vidData['mp4_url'];
        }

        this.loadVideoPlayer(vd);
        this.currentScene.addEventListener(Stage.NEXT_SCENE, (e) => this.startVideoPlayer(vd));
    }

    private loadVideoPlayer(vd:VideoData) {

        this.player = new VideoPlayer($("#container"), {}, this);
        this.player.loadVideo(vd);
    }

    public startVideoPlayer(vd:VideoData):void {
        $('canvas').remove();
        this.changeScene(new NickelScene());
        this.pause();

        this.player.playVideo();
        this.player.showMe();

        gaTrackEvent('film_start', 'film_playback');

        EventBus.addEventListener("VIDEO_OVER", () => {
            this.startGraveyardScene();
            gaTrackEvent('100% completion', 'film_playback');

        }, this);
    }

    public startGraveyardScene(expId?:string):void {

        let data:GraveyardData = {
            'api':this.api
        };

        if (this.vidData) {
            data.expId = this.vidData.id
        }

        this.changeScene(new NickelScene());
        this.pause();
        let graveyard = new Graveyard(this.cont, data, this);
    }
}

interface VideoData {
    src:string;
    expId:Uuid;
}

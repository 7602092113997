/// <reference path='../scaffolding/text/Word.ts'/>

class SeancesApi {
    public baseUrl:string;
    private fetch:FetchFn;

    constructor(fetch:FetchFn, baseUrl:string) {
        this.fetch = fetch;
        this.baseUrl = baseUrl;
    }

    public getExperienceGraveyard(page:number, id?:string):Promise<Object> {

        var path = `${this.baseUrl}get-experience-graveyard/`;
        if (id) {
            path += `${id}/${page}`;
        } else {
            path += `${page}`;
        }
        return this.fetch(path)
            .then((response:Response) => response.json());
    }

    public getExperience():Promise<Object> {
        return this.fetch(`${this.baseUrl}get-experience`)
            .then((response:Response) => response.json());
    }


    public getGraveyardCount():Promise<Object> {
        console.log(this.baseUrl)
        return this.fetch(`http://seances.nfb.ca/api?task=get-experience-counts`)
            .then((response:Response) => response.json());
    }

    public moveExperienceToGraveyard(experienceId:Uuid):Promise<Object> {
        return this.fetch(`${this.baseUrl}move-experience-to-graveyard/${experienceId}`, {
                method: 'post',
                headers: {'Accept': 'application/json'},
                body: ''

            })
            .then((response:Response) => response.json());
    }
}

type FetchFn = (url:string, options?:FetchOptions) => Promise<Response>;

interface GetExperienceResponse {
    id:string;
    title:TitleData[];

    actors:string[];
    a:ExperienceChunk;
    b:ExperienceChunk;
    c:ExperienceChunk;
    film_length:number;
    m3u8_url:string;
    mp4_url:string;
    synopses:string[];
    template_sequence:Template[];
    genres:GenreData;
}

interface TitleData {
    type:string;
    string:string;
}

interface ExperienceChunk {
    chunk_index:number;
    colour:string;
    film_id:number;
    film_name:string;
    part_index:number;
    word_pool:WordPool;
}

type WordPool = {[key:string]:WordPair[]};

interface WordPair {
    plural:string;
    singular:string;
}

type Template = TemplateKV[];

interface TemplateKV {
    type:string;
    value?:string;
    list?:string[];
}

interface GenreData {
    adjectives:Genre[];
    nouns:Genre[];
}

interface Genre {
    genre:string;
    italics:boolean;
    word?:Word;
}

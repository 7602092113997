class InstallationVideoBackground extends VideoBackground {

    constructor(cameraHeight:number, videoPool:string[]) {

        super(cameraHeight, videoPool);

        this.vEle.loop = false;
        this.vEle.addEventListener('ended', () => {
            this.vEle.src = this.getRandomVideoUrl();
            this.vEle.load();
            this.vEle.play();
        }, false)
    }
}

/// <reference path='../scaffolding/Component.ts'/>

class SeancesMobileView extends Component {

    private bgImg;

    private bgImages:string[] = [
        "Grid_Image_Bits_of_Life_09.jpg",
        "Grid_Image_Bits_of_Life_10.jpg",
        "Grid_Image_Bits_of_life_13.jpg",
        "Grid_Image_Blind_Girl_06.jpg",
        "Grid_Image_Der_Januskopf_05.jpg",
        "Grid_Image_Der_Januskopf_06.jpg",
        "Grid_Image_Der_Januskopf_07.jpg",
        "Grid_Image_Dream_Woman_02.jpg",
        "Grid_Image_Dream_Woman_021.jpg",
        "Grid_Image_Dream_Woman_04.jpg",
        "Grid_Image_Forbidden_Room_04.jpg",
        "Grid_Image_GBS_01.jpg",
        "Grid_Image_Saint_Devil_Woman_03.jpg",
        "Grid_Image_Saint_Devil_Woman_04.jpg",
        "Grid_Image_Saint_Devil_Woman_051.jpg",
        "Grid_Image_Scorching_Flame_03.jpg",
        "Grid_Image_Tokyo_Ginza_Disctrict_04.jpg",
        "Grid_Image_Tokyo_Ginza_District_03.jpg",
        "Grid_Image_Women_Skeletons_08.jpg",
        "Grid_Image_Women_Skeletons_09.jpg"
    ];

    constructor(container, data, del) {

        super(container, data, del);

        this.setContent(Main.templates.find('.mobileInterface').clone());

        this.container.find('.btnBegin').bind('click', (e)=> {
            e.preventDefault();
            EventBus.dispatchEvent('PLAY_FILM');
        });
        this.setBackground();
        this.resize();
    }

    private setBackground() {

        let img = this.getRandomImage();
        this.bgImg = new Image();
        this.bgImg.crossOrigin = "anonymous";
        this.bgImg.onload = () => {
            this.content.find('.bg').css('background-image', 'url(' + img + ')');
        };
        this.bgImg.onerror = () => {};
        this.bgImg.src = img;
    }

    public resize() {

        if (Main.sWidth > Main.sHeight) {
            this.content.removeClass('landscape');
        } else {
            this.content.addClass('landscape');
        }
    }

    private getRandomImage():string {
        return CDN_GLOBAL_PREFIX + '/img/bg_img/' + _.sample(this.bgImages);
    }
}

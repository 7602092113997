class MaskPass implements EffectPass {
    public enabled:boolean = true;
    public needsSwap:boolean = false;

    private scene:THREE.Scene;
    private camera:THREE.Camera;
    private clear:boolean = true;
    private inverse:boolean = false;

    constructor(scene:THREE.Scene, camera:THREE.Camera) {
        this.scene = scene;
        this.camera = camera;
    }

    public render(renderer, writeBuffer, readBuffer, delta) {

        let context = renderer.context;

        // don't update color or depth

        context.colorMask(false, false, false, false);
        context.depthMask(false);

        // set up stencil

        let writeValue, clearValue;

        if (this.inverse) {
            writeValue = 0;
            clearValue = 1;
        } else {
            writeValue = 1;
            clearValue = 0;
        }

        context.enable(context.STENCIL_TEST);
        context.stencilOp(context.REPLACE, context.REPLACE, context.REPLACE);
        context.stencilFunc(context.ALWAYS, writeValue, 0xffffffff);
        context.clearStencil(clearValue);

        // draw into the stencil buffer

        renderer.render(this.scene, this.camera, readBuffer, this.clear);
        renderer.render(this.scene, this.camera, writeBuffer, this.clear);

        // re-enable update of color and depth

        context.colorMask(true, true, true, true);
        context.depthMask(true);

        // only render where stencil is set to 1

        context.stencilFunc(context.EQUAL, 1, 0xffffffff);  // draw if == 1
        context.stencilOp(context.KEEP, context.KEEP, context.KEEP);

    }
}

class ClearMaskPass implements EffectPass {
    public enabled:boolean = true;
    public needsSwap:boolean = false;

    public render( renderer, writeBuffer, readBuffer, delta ) {
        var context = renderer.context;
        context.disable( context.STENCIL_TEST );
    }
}

/// <reference path='../SeancesMobileView.ts'/>
/// <reference path='../graveyard/Graveyard.ts'/>

class MobileStage {

    private api:SeancesApi;
    private data:StageParams;
    private player:VideoPlayer;
    private graveyard: Graveyard;
    private control:SeancesMobileView;
    private uuid:string = Utils.generateUUID();
    private expId: Uuid;

    constructor(api:SeancesApi, data:StageParams, skipIntro, graveyard) {

        this.data = data;
        this.api = api;

        if (skipIntro) {
            this.startExperience();
        } else {
            this.startIntroduction();
        }
    }

    public startIntroduction() {
        const intro = new IntroSceneNoThree($('#container'), {}, this);
        EventBus.addEventListener("INTRO_OVER", () => {
            intro.killMe();
            this.startExperience();
        }, this);
    }

    public startExperience() {

        console.log("start experience");

        this.api.getExperience()
            .then((e) => e['data'])
            .then((d) => {

                console.log(d);

                let url = (d['m3u8_url']) ? d['m3u8_url'] : d['mp4_url'];
                this.expId = <Uuid>d['id'];
                let vd = {
                    src: url,
                    expId: <Uuid>d['id']
                };

                this.loadVideoPlayer(vd);
                this.startMobileInterface();

                EventBus.addEventListener("PLAY_FILM", () => {
                    this.control.hideMe();
                    this.startVideoPlayer(vd);
                }, this);

            });

    }

    private startMobileInterface() {

        this.control = new SeancesMobileView(this.data.container, {}, this);
    }

    private loadVideoPlayer(vd:VideoData) {

        this.player = new VideoPlayer(this.data.container, {}, this);
        this.player.loadVideo(vd);
    }

    public startVideoPlayer(vd:VideoData):void {

        this.player.playVideo();
        this.player.showMe();

        this.api.moveExperienceToGraveyard(vd.expId);

        EventBus.addEventListener("VIDEO_OVER", () => {
            this.startGraveyardScene(); //TODO Mobile Graveyard
        }, this);
    }

    public startGraveyardScene(expId?:string):void {

        this.graveyard = new Graveyard(this.data.container, {"api":this.api, "id":this.expId}, this);
    }
}

class ShaderPass implements EffectPass {
    public enabled:boolean = true;
    public needsSwap:boolean = true;

    public renderToScreen:boolean = false;
    public uniforms;

    private textureID:string = "tDiffuse";
    private material:THREE.ShaderMaterial;
    private clear:boolean = false;
    private camera:THREE.Camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
    private scene:THREE.Scene = new THREE.Scene();
    private quad:THREE.Mesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(2, 2), null);

    constructor(shader, textureID?:string) {
        if (textureID) this.textureID = textureID;

        if (shader instanceof THREE.ShaderMaterial) {
            this.uniforms = shader.uniforms;
            this.material = shader;
        } else if (shader) {
            this.uniforms = THREE.UniformsUtils.clone(shader.uniforms);
            this.material = new THREE.ShaderMaterial({
                defines: shader.defines || {},
                uniforms: this.uniforms,
                vertexShader: shader.vertexShader,
                fragmentShader: shader.fragmentShader
            });
        }

        this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
        this.scene = new THREE.Scene();

        this.quad = new THREE.Mesh(new THREE.PlaneBufferGeometry(2, 2), null);
        this.scene.add(this.quad);
    }

    public render(renderer, writeBuffer, readBuffer, delta) {

        if (this.uniforms[this.textureID]) {
            this.uniforms[this.textureID].value = readBuffer.texture;
        }

        this.quad.material = this.material;

        if (this.renderToScreen) {
            renderer.render(this.scene, this.camera);
        } else {
            renderer.render(this.scene, this.camera, writeBuffer, this.clear);
        }
    }
}

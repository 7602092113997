/// <reference path='../../scaffolding/Component.ts'/>

class SeancesScene extends NickelScene {
    protected backgroundPool:string[] = [
        'bol_1g.mp4',
        'db_1g.mp4',
        'dh_1.mp4',
        'dh_2.mp4',
        'dh_3g.mp4',
        'dj_1g.mp4',
        'dj_2g.mp4',
        'dj_3g.mp4',
        'foac_1.mp4',
        'fr_1.mp4',
        'gl_1.mp4',
        'jk_1.mp4',
        'pp_1g.mp4',
        'rausch_1.mp4',
        'sdw_1.mp4',
        'sdw_2g.mp4',
        'sdw_3g.mp4',
        'sdw_4g.mp4',
        'tgd_1.mp4',
        'ti_1.mp4'
    ];

    constructor() {
        super();
        const light = new THREE.AmbientLight(0xffffff);
        this.add(light);
    }
}

class CardLayer extends NickelGroup {

    private velocity:number;

    private tweenVelocity:boolean = true;
    private velocityTween:TweenMax;
    private velocityMin:number;
    private velocityMax:number;
    private minTweenDur:number = 2;
    private maxTweenDur:number = 10;
    private divider:number = 2000;
    private minDelay:number = 0;
    private maxDelay:number = 100;

    constructor(vel:number, pos:THREE.Vector3) {

        super();

        this.velocity = vel / this.divider;
        this.velocityMin = vel * 0.4;
        this.velocityMax = vel * 1.6;

        this.position.copy(pos);

        if (MultiTouchInterfaceScene.ROTATE) {
            this.addEventListener(NickelScene.TICK_EVENT, () => {
                this.rotation.z += this.velocity;
            });
        }

        if (this.tweenVelocity) {
            this.animateVelocity();
        }

        this.rotation.z = 0.5;
    }

    private animateVelocity() {

        let dly = _.random(this.minDelay, this.maxDelay) / 100;
        let targ = _.random(this.velocityMin, this.velocityMax) / this.divider;

        this.velocityTween = TweenMax.to(this, _.random(this.minTweenDur, this.maxTweenDur), {
            velocity: targ,
            ease: Quad.easeInOut,
            delay: dly,
            onComplete: ()=>this.animateVelocity()
        })
    }
}

interface TitleTemplated {
    string:string;
    type:string;
    colour:string;
    word?:Word[];
    dirty?:boolean;
    plural?:boolean;
    originalPlurality?: string; // "plural" | "singular" | "any"
}

class TitleGenerator {
    private finalize:boolean = false;

    private pools:ExperienceChunk[];
    private tSeq:Template[];
    private currentTemplate:Template;
    private finalTitle:TitleData[];
    private currentIdx:number = 0;

    private last:TitleTemplated[];

    constructor(tSeq:Template[], pools:ExperienceChunk[], finalTitle:TitleData[]) {
        this.tSeq = tSeq;
        this.pools = pools;
        this.finalTitle = finalTitle;
        this.currentTemplate = this.tSeq[0];
    }

    public finalized():boolean {
        return this.finalize;
    }

    public update(finalize:boolean):TitleTemplated[] {
        this.finalize = finalize;

        if (this.finalize || !this.last || Math.random() > 0.75) {
            return this.generate();
        } else {
            return this.swap();
        }
    }

    private swap():TitleTemplated[] {
        const rand = _.sample<TitleTemplated>(this.last);
        const idx = this.last.indexOf(rand);
        const t = _.findWhere(this.currentTemplate, {type: rand.type});
        const oldString = this.last[idx].string;
        const oldPlurality = this.last[idx].plural;

        if (this.last[idx].type === 'modifier') {
            this.last[idx].string = _.sample<string>(t.list);
        } else {
            let plurality:string = t.value !== 'any' ? t.value : _.sample<string>(['plural', 'singular']);
            const pool = _.sample<ExperienceChunk>(this.pools);
            this.last[idx].string = _.sample<WordPair>(pool.word_pool[t.type])[plurality];
            this.last[idx].originalPlurality = t.value;
            this.last[idx].plural = plurality === 'plural';
        }
        this.last[idx].dirty = oldString !== this.last[idx].string || oldPlurality !== this.last[idx].plural;

        if (this.last[idx].dirty) {
            return this.last;
        } else {
            return this.swap();
        }
    }

    private generate():TitleTemplated[] {
        const title:TitleTemplated[] = [];
        let foundMatch = !this.finalize;

        do {
            if (++this.currentIdx >= this.tSeq.length) {
                this.currentIdx = 0;
            }
            this.currentTemplate = this.tSeq[this.currentIdx];

            this.currentTemplate.forEach(current => {
                this.finalTitle.forEach(final => {
                    if (current.type !== 'modifier' && current.type === final.type) {
                        foundMatch = true;
                    }
                })
            });
        } while (!foundMatch);

        const pool = _.sample<ExperienceChunk>(this.pools);

        const skipIdx = this.finalize ? _.findIndex(this.currentTemplate, ct => <boolean>_.chain<TemplateKV>(this.currentTemplate)
            .filter(t => t.type !== 'modifier')
            .sample()
            .isEqual(ct) // this is why <boolean>
            .pop()
            .value()) : -1;

        this.currentTemplate.forEach((t:TemplateKV, idx:number) => {
            if (this.finalize && t.type !== 'modifier' && skipIdx !== idx) {
                let finalId = _.findIndex(this.finalTitle, ft => ft.type === t.type);

                if (finalId !== -1) {
                    title[idx] = {
                        string: this.finalTitle[finalId].string,
                        type: this.finalTitle[finalId].type,
                        colour: '0xFFFFFF',
                        dirty: true,
                        word: []
                    };
                    return;
                }
            }

            if (t.type === 'modifier') {
                title[idx] = {
                    string: _.sample<string>(t.list),
                    type: 'modifier',
                    colour: '0xFFFFFF',
                    dirty: true,
                    word: []
                };
                return;
            }

            let plurality:string = t.value !== 'any' ? t.value : _.sample<string>(['plural', 'singular']);

            const lastIdx = _.findIndex(this.last, v => v.type === t.type && v.originalPlurality === t.value);

            if (lastIdx !== -1) {
                title[idx] = this.last[lastIdx];
                delete this.last[lastIdx];
                this.last = _.compact(this.last);
                return;
            }

            title[idx] = {
                string: _.sample<WordPair>(pool.word_pool[t.type])[plurality],
                type: t.type,
                colour: '0xFFFFFF',
                dirty: true,
                word: [],
                plural: plurality === 'plural',
                originalPlurality: t.value
            };
        });


        this.last = title;
        return title;
    }

    public wordList():string[] {
        const list = [];

        this.pools.forEach((p:ExperienceChunk) => {
            _.map(p.word_pool, (wps):void => {
                wps.forEach((wp:WordPair) => {
                    list.push(wp.plural, wp.singular)
                })
            })
        });
        this.tSeq.forEach((t:Template) => t.forEach((tkv:TemplateKV) => {
            if (tkv.type === 'modifier') {
                tkv.list.forEach((m:string) => list.push(m));
            }
        }));

        return list;
    }
}

class Share {

    static onTwitter(shareUrl, tweetWithoutUrl) {
        //build tweet URL
        var url = 'https://twitter.com/intent/tweet?'

        if (shareUrl) {
            url += 'url=' + encodeURIComponent(shareUrl) + "&"
        }

        if (tweetWithoutUrl) {
            url += 'text=' + encodeURIComponent(tweetWithoutUrl);
        }

        Utils.openWindow(url, 550, 480);
    }

    static onFacebook(shareUrl) {
        var url = 'http://www.facebook.com/sharer.php?s=100&p[url]=' + encodeURIComponent(shareUrl);
        Utils.openWindow(url, 550, 480);
    }
}

class Rect {
    private x:number;
    private y:number;
    public w:number;
    public h:number;

    private padding:number;

    constructor(x:number, y:number, w:number, h:number, padding?:number) {
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;

        this.padding = padding || 0;
    }

    get interiorWidth() {
        return this.w;
    }

    get interiorHeight() {
        return this.h;
    }

    get exteriorWidth() {
        return this.w + this.padding;
    }

    get exteriorHeight() {
        return this.h;
    }

    get interiorX1() {
        return this.x + this.padding;
    }

    get interiorX2() {
        return this.x + this.interiorWidth;
    }

    get exteriorX1() {
        return this.x;
    }

    get exteriorX2() {
        return this.x + this.exteriorWidth;
    }

    get interiorY1() {
        return this.y + this.padding;
    }

    get interiorY2() {
        return this.y + this.interiorHeight;
    }

    get exteriorY1() {
        return this.y;
    }

    get exteriorY2() {
        return this.y + this.exteriorHeight;
    }

    set x1(i:number) {
        this.x = i;
    }

    set x2(i:number) {
        this.x = i - this.w;
    }

    set y1(i:number) {
        this.y = i;
    }

    set y2(i:number) {
        this.h = i - this.h;
    }
}

/// <reference path='../../scaffolding/Component.ts'/>

class InstallationControls extends Component {

    public socket:any;
    public confirm:boolean = false;
    private btnReset:JQuery;
    private btnPlay:JQuery;
    private btnInit:JQuery;
    private btnReady:JQuery;
    private viewersInput:JQuery;

    constructor(container, data, delegate) {

        super(container, data, delegate);

        this.socket = io(Main.socket);
        this.socket.on('connect', (evt)=>this.connectedToSocket(evt));
        this.socket.on('error', (evt)=>this.connectionError(evt));
        this.socket.on('reconnect', (evt)=>this.connectedToSocket(evt));
        this.socket.on('disconnect', (evt)=>this.disconnectedFromSocket(evt));
        this.socket.on('reset', (evt)=>this.reset(evt));

        this.setContent(Main.templates.find('.controls').clone());

        this.viewersInput = this.content.find('.viewersInput');

        this.btnReset = this.content.find('.btnReset').on('click', ()=>this.resetClicked());
        this.btnPlay = this.content.find('.btnPlayFilm').on('click', ()=>this.playClicked());
        this.btnInit = this.content.find('.btnInitInterface').on('click', ()=>this.initInterface());
        this.btnReady = this.content.find('.btnGetReady').on('click', ()=>this.getReady());
    }

    private reset(evt) {
        location.reload();
    }

    private getReady() {

        this.btnPlay.prop('disabled', false);
        this.viewersInput.prop('disabled', false);
        this.btnReady.prop('disabled', true);

        if (this.confirm) {
            let c = confirm("Are you sure you want to get ready?");
            if (!c) return;
        }

        this.socket.emit('getReady');
    }

    private initInterface() {

        this.btnReady.prop('disabled', false);
        this.btnInit.prop('disabled', true);

        if (this.confirm) {
            let c = confirm("Are you sure you want to Load the first film?");
            if (!c) return;
        }

        this.socket.emit('initInterface');
    }

    private resetClicked() {

        if (this.confirm) {
            let c = confirm("Are you sure you want to Reset All Screens?");
            if (!c) return;
        }

        this.socket.emit('reset');
    }

    private playClicked() {

        this.btnPlay.prop('disabled', true);
        this.viewersInput.prop('disabled', true);

        //track the viewers
        let viewers = this.viewersInput.val();
        
        gaTrackEvent(viewers, 'playInstallationFilm');
        this.viewersInput.val('');

        if (this.confirm) {
            let c = confirm("Are you sure you want to Play the film: [filmname]");
            if (!c) return;
        }

        this.socket.emit('playFilm');

    }

    private connectionError(evt) {

        console.log("Socket Connection Error");
        console.log(evt);
    }

    private connectedToSocket(evt) {

        console.log("Socket Connection Successful");

        this.socket.emit('joinRoom', {'roomName': Main.room});
    }

    private disconnectedFromSocket(evt) {

        console.log("Disconnected from Socket");
    }
}

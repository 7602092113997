class BaseTitleSequence extends NickelGroup {
    private renderer:LineTextureRenderer;
    public titleGenerator:TitleGenerator;

    public lastTitles:TitleTemplated[] = [];
    private padding:number = 2;

    private pivotX:number = 0;
    private pivotY:number = 0;
    private pivotZ:number = 0;

    public timeline:TimelineMax;

    constructor(renderer:LineTextureRenderer, titleGenerator:TitleGenerator) {
        super();
        this.titleGenerator = titleGenerator;
        this.renderer = renderer;

        this.preRenderText();

        this.addEventListener(SeancesIntroScene.TITLE_SWAP, () => this.shift(false));
        this.addEventListener(SeancesIntroScene.FINALIZE_TITLE, () => this.shift(true));

        this.timeline = new TimelineMax();

        this.rotation.order = 'ZYX';
        this.startXPivot();
        this.startYPivot();
        this.startZPivot();
    }

    public shift(finalize:boolean) {
        this.timeline = new TimelineMax();

        const titles:TitleTemplated[] = this.titleGenerator.update(finalize);

        this.updateTitles(titles);

        this.lastTitles = titles;
    }

    public updateTitles(titles:TitleTemplated[]):void {
        titles.forEach(t => this.copyReusableTemplates(t));

        const oldUuid = _.filter(this.children, c => c instanceof Word).map(c => c.uuid);

        titles.forEach(t => this.updateDirtyTitleTemplate(t));

        const newUuid = _.pluck(_.flatten(titles.map(c => c.word)), 'uuid');
        const remove = _.difference(oldUuid, newUuid);
        const add = _.difference(newUuid, oldUuid);

        const titleWords = titles.reduce((c, l) => c + l.word.length, 0) - 1;
        const packedWidth = titles.reduce((c, l:TitleTemplated) => {
            return c + l.word.reduce((c2, w) => {
                    return c2 + w.width();
                }, 0)
        }, this.padding * titleWords);

        let pos = -packedWidth / 2;

        titles.forEach(l => l.word.forEach((w, i) => {
            pos += w.width() / 2;
            if (add.indexOf(w.uuid) !== -1) {
                w.position.x = pos;

                this.timeline
                    .add(TweenMax.fromTo(w.position, 0.5, {z: 50}, {z: 0}), 0)
                    .add(TweenMax.fromTo(w.material.uniforms.vColour.value, 1, {w: 0}, {
                        w: 1,
                        delay: l.word.length > 1 ? Math.random() / 4 : 0,
                        onStart: () => this.add(w)
                    }), 0);

            } else if (remove.indexOf(w.uuid) === -1) {
                this.timeline
                    .add(TweenMax.to(w.position, 1, {x: pos}), 0);
            }

            pos += w.width() / 2;
            pos += this.padding;
        }));

        remove.forEach(uuid => {
            const word = <Word>this.getObjectByProperty('uuid', uuid);
            if (word) {
                this.timeline
                    .add(TweenMax.to(word.position, 0.5, {z: -100}), 0)
                    .add(TweenMax.to(word.material.uniforms.vColour.value, 0.75, {
                        w: 0,
                        onComplete: () => this.remove(word)
                    }), 0);
            }
        });

        this.timeline.play();
    }

    private updateDirtyTitleTemplate(t:TitleTemplated):void {
        if (t.dirty) {
            const newWords = t.string.split(' ').map(s => Word.makeWord({
                text: s,
                lineHeight: 10,
                colour: new THREE.Color(parseInt(t.colour)),
                yRange: 0,
                shaderMotion: true,
                distanceFadeOut: true
            }, this.renderer));

            if (t.word) {
                t.word.forEach((w, idx) => {
                    if (newWords[idx]) {
                        newWords[idx].position.add(w.position);
                        newWords[idx].material.uniforms.fTime.value = w.material.uniforms.fTime.value;
                    }
                });
            }

            t.word = newWords;
            t.dirty = false;
        }
        return;
    }

    private copyReusableTemplates(t:TitleTemplated) {
        const dirty = t.dirty;
        delete t.dirty;

        let tt = _.find<TitleTemplated>(this.lastTitles, tt => _.isMatch(tt, t));

        t.dirty = dirty;
        if (tt) {
            t.word = tt.word;
        }
    }

    protected getPivotFactor():number {
        const rand = Math.random();
        return rand > 0.5 ? rand : rand - 1;
    }

    private startXPivot() {
        TweenMax.to(this, 8,
            {
                pivotX: this.getPivotFactor() * 2 * Math.PI / 32,
                ease: Expo.easeOut,
                onUpdate: () => this.rotation.x = this.pivotX,
                onComplete: () => this.startXPivot()
            }
        );
    }

    private startYPivot() {
        TweenMax.to(this, 4,
            {
                pivotY: this.getPivotFactor() * 2 * Math.PI / 8,
                ease: Expo.easeOut,
                onUpdate: () => this.rotation.y = this.pivotY,
                onComplete: () => this.startYPivot()
            }
        );
    }

    private startZPivot() {
        TweenMax.to(this, 4,
            {
                pivotZ: this.getPivotFactor() * 2 * Math.PI / 64,
                ease: Expo.easeOut,
                onUpdate: () => this.rotation.z = this.pivotZ,
                onComplete: () => this.startZPivot()
            }
        );
    }

    private preRenderText() {
        this.titleGenerator.wordList().forEach(l => l.split(' ').forEach(w => this.renderer.makeLineTexture(w)));
    }
}

class TitleSequence extends BaseTitleSequence {
    public shift(finalize:boolean) {
        if (this.timeline.isActive()) {
            return;
        }
        return super.shift(finalize);
    }
}

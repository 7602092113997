class CopyShader implements THREE.ShaderMaterialParameters {

    public uniforms = {
        "tDiffuse": { type: "t", value: null },
        "opacity":  { type: "f", value: 1.0 }
    };

    public vertexShader = `
varying vec2 vUv;

void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`;

    public fragmentShader = `
uniform float opacity;
uniform sampler2D tDiffuse;

varying vec2 vUv;

void main() {
    vec4 texel = texture2D( tDiffuse, vUv );
    gl_FragColor = opacity * texel;
}
`;

}

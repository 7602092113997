/// <reference path='../../scaffolding/Main.ts'/>
/// <reference path='../../scaffolding/EventBus.ts'/>
/// <reference path='../../scaffolding/Utils.ts'/>
/// <reference path='../../scaffolding/Component.ts'/>
/// <reference path='./InstallationGraveyardTitle.ts'/>
/// <reference path='../../scaffolding/VirtualScroller.ts'/>

class InstallationGraveyard extends Component {
    private atPage: number = 1;
    private totalPages: number;
    private titleArray: InstallationGraveyardTitle[] = [];
    private contentDiv: any;
    private api: any;
    private bgImg;
    private firstLoad: boolean = true;
    private lineHeight:number = 55;
    private virtualScroller:VirtualScroll;
    private triggerPadding:number = 100;
    private scrollPadding:number = 20;
    private atY:number = 0;
    private gettingPage:boolean = false;
    private sHeight:number;
    private clearInterval:number;

    protected backgroundPool:string[] = [
        'bol_1g.mp4',
        'db_1g.mp4',
        'dh_1.mp4',
        'dh_2.mp4',
        'dh_3g.mp4',
        'dj_1g.mp4',
        'dj_2g.mp4',
        'dj_3g.mp4',
        'foac_1.mp4',
        'fr_1.mp4',
        'gl_1.mp4',
        'jk_1.mp4',
        'pp_1g.mp4',
        'rausch_1.mp4',
        'sdw_1.mp4',
        'sdw_2g.mp4',
        'sdw_3g.mp4',
        'sdw_4g.mp4',
        'tgd_1.mp4',
        'ti_1.mp4'
    ];

    private pageData:any = [];
    private bgVideo:JQuery;
    private scrollY:number = 0;

    constructor(container:JQuery, data:any, delegate:any) {
        super(container, data, delegate);
        
        this.setContent(Main.templates.find('.installationGraveyard').clone());
        
        this.api = this.data.api;
        this.api.baseUrl = "http://seances-900865127.us-east-1.elb.amazonaws.com/";

        this.bgVideo = this.content.find('.graveyardBgVideo');
        this.bgVideo.on('ended', ()=>this.setBackground());

        this.setBackground();
        this.contentDiv = this.content.find('.content');
        this.resize();
        
        this.virtualScroller = new VirtualScroll();
        this.virtualScroller.attach();
        this.virtualScroller.setValue(0, -this.scrollPadding);
        EventBus.addEventListener(VirtualScroll.UPDATE, (evt)=>this.updatePosition(evt), this);

        this.getData();

        this.clearInterval = setInterval(()=>this.clearHidden(), 10000);

        this.autoScroll(0.5);
    }

    private clearHidden(){

        var i = this.titleArray.length;
        while (i--) {

            let title = this.titleArray[i];
            let top = title.data.top;

            if(top + this.lineHeight < this.scrollY){
                title.killMe();
                this.titleArray.splice(i, 1);
            }    
        }
    }

    public resize(){

        this.sHeight = $(window).height();

    }

    private updatePosition(evt) {

        this.scrollY = evt.scrollY;
        this.contentDiv[0].style[Main.xform] = 'translateY(-' + this.scrollY+ 'px)';

        if (this.scrollY> (this.atY - this.sHeight - this.triggerPadding) && !this.gettingPage) {

            this.getData();
        }
    }

    public setBackground(){

        let src = this.getRandomVideo();
        this.bgVideo.attr('src', src);
        let el = <HTMLVideoElement>this.bgVideo[0];
        el.load();
        el.play();

    }

    public getData(){

        this.gettingPage = true;

        if (this.totalPages && this.atPage > this.totalPages) {
            this.atPage = 1;
        }

        this.api.getExperienceGraveyard(this.atPage, this.data.expId)
            .then((e) => {

                this.totalPages = e['total_pages'];
                this.addTitles(e['data']);
                this.pageData[this.atPage-1] = e['data'];

                this.gettingPage = false;
                this.atPage++;
                
            },
            (e)=>{
                let data = _.sample(this.pageData);
                this.addTitles(data);

                this.gettingPage = false;
                this.atPage++;
            });
    }

    public addTitles(titles){

        for (var  i = 0; i < titles.length; i++){
            let data = titles[i];
            data.top = this.atY;
            let movieTitle = new InstallationGraveyardTitle(this.contentDiv, data, this);
            this.titleArray.push(movieTitle);

            this.atY += this.lineHeight;
            this.setMaxY();
        }
    }

    private getRandomVideo():string {
        return CDN_GLOBAL_PREFIX + '/vid/backgrounds/' + _.sample(this.backgroundPool);
    }

    public autoScroll(velocity:number) {

        this.virtualScroller.enableAutoScroll(velocity);
    }

    public setMaxY() {
        let offset = 0;
        this.virtualScroller.minY = -this.scrollPadding;
        this.virtualScroller.maxY = this.atY - this.sHeight + this.scrollPadding - offset;
    }
}

/// <reference path="../title/GenreSequence.ts" />

class InstallationGenreSequence extends GenreSequence {

    constructor(renderer:LineTextureRenderer, genreGenerator:GenreGenerator) {

        super(renderer, genreGenerator);

        EventBus.addEventListener(PhotoCard.ADD_TO_TITLE, () => this.shift(), this);
    }
}

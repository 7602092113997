class GenreSequence extends NickelGroup {
    private renderer:LineTextureRenderer;
    private genreGenerator:GenreGenerator;

    private lastGenres:Genre[] = [];
    private padding:number = 1;

    private timeline:TimelineMax;

    constructor(renderer:LineTextureRenderer, genreGenerator:GenreGenerator) {
        super();
        this.renderer = renderer;
        this.genreGenerator = genreGenerator;

        this.preRenderText();

        this.addEventListener(SeancesIntroScene.TITLE_SWAP, () => this.shift());
        this.addEventListener(SeancesIntroScene.FINALIZE_TITLE, () => {
            this.genreGenerator.lockFilmLength();
            this.shift();
        });

        this.timeline = new TimelineMax();
    }

    protected shift() {
        if (this.timeline.isActive()) {
            return;
        }
        this.timeline = new TimelineMax();

        const genres = this.genreGenerator.generate();
        genres.forEach(g => this.copyReusable(g));

        const oldUuid = this.children.map(c => c.uuid);

        genres.forEach(g => this.createGenreWord(g));

        const newUuid = _.pluck(_.flatten(genres.map(c => c.word)), 'uuid');
        const remove = _.difference(oldUuid, newUuid);
        const add = _.difference(newUuid, oldUuid);

        const packedWidth = genres.reduce((c, l) => {
            return c + l.word.width();
        }, this.padding * genres.length);

        let pos = -packedWidth / 2;

        genres.forEach(l => {
            let w = l.word;
            pos += l.word.width() / 2;

            if (add.indexOf(l.word.uuid) !== -1) {
                w.position.x = pos;

                this.timeline.add(
                    TweenMax.fromTo(w.material.uniforms.vColour.value, 1, {w: 0},
                        {w: (Math.random() * 0.3) + 0.7, onStart: () => this.add(w)}
                    ), 0);

            } else if (remove.indexOf(w.uuid) === -1) {
                this.timeline.add(TweenMax.to(w.position, 1, {x: pos}), 0);
            }

            pos += w.width() / 2;
            pos += this.padding;
        });

        remove.forEach(uuid => {
            const word = <Word>this.getObjectByProperty('uuid', uuid);
            if (word) {
                this.timeline.add(TweenMax.to(word.material.uniforms.vColour.value, 0.75,
                    {w: 0, onComplete: () => this.remove(word)}
                ), 0);
            }
        });

        this.lastGenres = genres;

        this.timeline.play();
    }

    private createGenreWord(gd:Genre) {
        if (!gd.word) {
            gd.word = Word.makeWord({
                text: gd.genre,
                lineHeight: 10,
                colour: new THREE.Color(parseInt('0xFFFFFF')),
                yRange: 0,
                shaderMotion: false,
                smallCaps: false,
                italic: gd.italics
            }, this.renderer);
        }
    }

    private copyReusable(gd:Genre) {
        let gdgd = _.find<Genre>(this.lastGenres, gdgd => _.isMatch(gdgd, gd));

        if (gdgd) {
            gd.word = gdgd.word;
        }
    }

    private preRenderText() {
        this.genreGenerator.wordList().forEach(l => {
            const extra = l.italics ? ['italic'] : [];
            this.renderer.makeLineTexture(l.genre, extra);
        });
    }
}

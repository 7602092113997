class GenreGenerator {
    private genreData:GenreData;
    private filmLength:number;

    private startsWithVowel = {
        6: false,
        7: false,
        8: true,
        9: false,
        10: false,
        11: true,
        12: false,
        13: false,
        14: false,
        15: false,
        16: false,
        17: false,
        18: true,
        19: false,
        20: false,
        21: false,
        22: false
    };

    private lastGenres:Genre[] = [];
    private lastFilmTime:number;
    private lastGenerated:Genre[] = [];

    private mapping = ['adjectives', 'adjectives', 'nouns'];

    private locked:boolean = false;

    constructor(genreData:GenreData, filmLength:number) {
        this.genreData = genreData;
        this.filmLength = filmLength;
    }

    public generate():Genre[] {
        while (this.lastGenres.reduce((c, l) => {
            // this is bad and I feel bad
            return (l !== undefined) ? c + 1 : c;
        }, 0) !== 3) {
            this.changeGenreText();
        }

        if (!this.locked)
            this.changeFilmLength();
        this.changeGenreText();

        this.lastGenerated = [{
            genre: this.startsWithVowel[this.lastFilmTime] ? 'An' : 'A',
            italics: false
        }, {
            genre: this.lastFilmTime.toString(),
            italics: false
        }, {
            genre: 'minute',
            italics: false
        },
            this.lastGenres[0],
            this.lastGenres[1],
            this.lastGenres[2]
        ];

        this.locked = false;

        return this.lastGenerated;
    }

    public wordList():Genre[] {
        const genres = [];
        for (let idx in this.genreData) {
            genres.push(...this.genreData[idx]);
        }
        return genres;
    }

    public lockFilmLength():void {
        this.lastFilmTime = this.filmLength;
        this.locked = true;
    }

    private changeFilmLength():void {
        this.lastFilmTime = Math.round(Math.random() * 8 + 13);
        return;
    }

    private changeGenreText(idx?:number):void {
        if (!idx) {
            idx = Math.floor(Math.random() * 3);
        }

        const type = this.mapping[idx];
        const genre = _.reject<Genre>(this.genreData[type], e => _.some(this.lastGenres, f => {
            if (f && e) return f.genre === e.genre;
        }));

        this.lastGenres[idx] = _.sample<Genre>(genre);
        return;
    }
}

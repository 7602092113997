class NickelScene extends THREE.Scene {
    public static TICK_EVENT = 'tick';

    constructor() {
        super();
        this.addEventListener(
            NickelScene.TICK_EVENT,
            e => this.children.forEach(c => c.dispatchEvent({
                type: NickelScene.TICK_EVENT,
                message: {dt: e.message.dt}
            }))
        );
        Stage.events.forEach(
            (e:string) => this.addEventListener(e,
                (msg) => this.children.forEach(
                    (c:THREE.Object3D) => c.dispatchEvent({type: e, message: msg}))));
    }

    public getPostProcessingPasses() {
        return [];
    }
}

class NullCursor extends THREE.Object3D implements Cursable {
    constructor() {
        super();
        this.visible = false;
    }

    updateProgress(progress:number):void {
    }

    updatePosition(mouse:THREE.Vector3):void {
    }

    reset():void {
    }
}

interface Cursable extends THREE.Object3D {
    updateProgress(progress:number):void;
    updatePosition(mouse:THREE.Vector3):void;
    reset():void;
}

/// <reference path='../../scaffolding/Component.ts'/>
/// <reference path='../../scaffolding/EventBus.ts'/>
class IntroSceneNoThree extends Component {

    private textCount:number = 0;
    private instruction:any;
    private outDelay:number = 3.5;
    private duration:number = 1.7;
    private textArray:any = [{
        "line1": "Guy Maddin, Evan Johnson, Galen Johnson and",
        "line2": "the National Film Board of Canada",
        "line3": "present"
    }, {
        "line1": "zero",
        "line2": "one",
        "line3": "two",
    }];

    constructor(container, data, delegate) {
        super(container, data, delegate);

        this.setContent(Main.templates.find('.intro').clone());

        this.resize();

        this.instruction = this.content.find(".instruction");
        this.startAnimating();
    }

    private startAnimating() {

        gaTrackEvent('intro', 'start');

        const logo = this.content.find('.logo');

        TweenMax.to(logo, this.duration, {'ease': Linear.easeNone, 'opacity': 1, delay: 0.5});
        TweenMax.to(logo, this.duration, {
            'ease': Linear.easeNone,
            'opacity': 0,
            delay: this.outDelay,
            onComplete: $.proxy(this.creditAnimate, this)
        });
    }

    private creditAnimate() {
        const credit = this.content.find(".credit");
        const creditText = this.textArray[0];
        for (let text in creditText) {
            let line = "<div class='textLine'>" + creditText[text] + "</div>";
            credit.append(line)
        }

        TweenMax.to(credit, this.duration, {'ease': Linear.easeNone, 'opacity': 1, delay: 0.5});
        TweenMax.to(credit, this.duration, {
            'ease': Linear.easeNone,
            'opacity': 0,
            delay: this.outDelay,
            onComplete: $.proxy(this.seancesAnimate, this)
        });
    }


    private seancesAnimate() {
        let seances = this.content.find(".seances");
        TweenMax.to(seances, this.duration, {'ease': Linear.easeNone, 'opacity': 1, delay: 0.5});
        TweenMax.to(seances, this.duration, {
            'ease': Linear.easeNone,
            'opacity': 0,
            delay: this.outDelay,
            onComplete: $.proxy(this.instructionText, this)
        });
    }

    private instructionText() {
        const instructionText = this.textArray[1];
        const offset = this.duration;
        let count = 0;
        for (let text in instructionText) {
            TweenMax.to($("." + instructionText[text]), 1, {
                'ease': Linear.easeNone,
                'opacity': 1,
                delay: offset * count,
                onComplete: $.proxy(this.textDoneAnimating, this)
            });
            count++;
        }
    }

    private removeTextNode() {
        this.instruction.find('div:first').remove();
    }

    private textDoneAnimating() {
        this.textCount++;
        if (this.textCount == 3) {
            setTimeout(function () {
                EventBus.dispatchEvent('INTRO_OVER');
                gaTrackEvent('intro', 'over');
            }, 2000);
        }
    }

    public resize(e = null) {

        if (this.content) {
            this.content.css({
                'width': Main.sWidth,
                'height': Main.sHeight
            });
        }

    }
}

/// <reference path="./ScrollPolyfill.ts" />

class VirtualScroll {

    private multiplier:number = 0.05;
    private event:any;
    private easing:number = 0.1;
    public minX:any = null;
    public maxX:any = null;
    public minY:any = 0;
    public maxY:any = 20000;
    private lastPageX:any = 0;
    private lastPageY:any = 0;
    private attached:any = false;
    private autoPilotMode:any = false;
    private startX:any;
    private startY:any;
    private targetX:any;
    private targetY:any;
    private duration:any;
    private t:any;
    private onWheelCallback:any;
    private initialHeight:number;
    private sHeight:number;
    private autoScroll:boolean = false;
    private velocity:number = 0.1;

    public static UPDATE:string = "vsupdate";

    constructor() {

        // if(Main.config.browser == "firefox") this.multiplier = 0.3;
        // if (Browsers.chrome()) multiplier = 2;
        // if (Browsers.ie()) multiplier = 4;
        // if (FJ.Capabilities.touch) multiplier = 2;

        this.event = {

            scrollX: 0,
            scrollY: 0,

            targetScrollX: 0,
            targetScrollY: 0,

            deltaX: 0,
            deltaY: 0,

            maxDeltaX: 0,
            maxDeltaY: 0,

            screenHeight: 0,
            maxY: this.maxY
        };

        this.sHeight = $(window).height();
    }

    private jumpTo(data?) {

        this.event.scrollY = data.yVal;
        this.event.targetScrollY = data.yVal;

        // this.scrollTo({x:0, y:data.yVal, d:30});
        // this._dispatch();
    }

    private updateFromScrollbar(data?) {

        //calculate the position based off of the percentage and then autoscroll there
        var pos = this.minY + Math.round((this.maxY - this.minY) * data.perc);
        this.scrollTo({x: 0, y: pos, d: 30});
    }

    private reset() {
        this.setValue(0, 0);
    }

    public setValue(x, y) {
        this.event.scrollX = x;
        this.event.scrollY = y;

        this.event.targetScrollX = x;
        this.event.targetScrollY = y;

        this.autoPilotMode = false;
    }

    public scrollTo(data?) {

        this.autoPilotMode = true;

        this.startX = this.event.scrollX;
        this.startY = this.event.scrollY;

        this.event.targetScrollX = data.x;
        this.event.targetScrollY = data.y;

        // 30 frames = roughyl 0.5s by default (but it could be estimated based on distance-to-travel)
        this.duration = data.d || 30;
        this.t = 0;
    }

    private initialScroll(data?) {

        this.initialHeight = data.y;
        this.scrollTo(data);
    }


    private autoScrollTo() {
        this.t++;

        var dy = Utils.easeQuadOut(this.startY, this.event.targetScrollY, this.t / this.duration);

        this._set(dy - this.event.scrollY);
        if (this.t >= this.duration) {
            this.autoPilotMode = false;
        }
    }

    private _set(dy) {
//			console.log(dy);
        // console.log("Set")
        if (this.easing && !this.autoPilotMode) {
            this.event.targetScrollY += dy;
        } else {
            this.event.scrollY += dy;
        }

        this.event.maxDeltaY = Math.max(this.event.maxDeltaY, Math.abs(dy));

        this.event.deltaY = dy;

        this.event.maxY = this.maxY;
        this.event.minY = this.minY;
        this.event.screenHeight = this.sHeight;
    }

    private touch() {
        this._dispatch();
    }

    private _dispatch() {

        if (this.minX != null) {

            this.event.scrollX = Utils.clamp(this.minX, this.maxX, this.event.scrollX);
            this.event.targetScrollX = Utils.clamp(this.minX, this.maxX, this.event.targetScrollX);
        }

        if (this.minY != null) {
            this.event.scrollY = Utils.clamp(this.minY, this.maxY, this.event.scrollY);
            this.event.targetScrollY = Utils.clamp(this.minY, this.maxY, this.event.targetScrollY);
        }

        // this.dispatcher.dispatch(this.event);
        // console.log("DISPATCH EVENT!!!");
        // $(document).trigger('setPos', this.event);

        EventBus.dispatchEvent(VirtualScroll.UPDATE, this.event);

    }

    public attach() {

        // if ('touchstart' in document.documentElement && 'touchmove' in document.documentElement) {
            document.addEventListener('touchstart', $.proxy(this.onTouchStart, this), false);
            document.addEventListener('touchmove', $.proxy(this.onTouchMove, this), false);
        // } else {
            this.onWheelCallback = addWheelListener(document, $.proxy(this.onWheel, this), false); // this will throw a ts complier error
            // this.onWheelCallback = addWheelListener(document, _.debounce( $.proxy(this.onWheel, this), 300), false); // this will throw a ts complier error
        // }

        TweenMax.ticker.addEventListener('tick', () => this.onFrame());
        //requestAnimationFrame($.proxy(this.onFrame, this));
        // FJ.FrameImpulse.addEventListener($.proxy(this.onFrame, this)); ///TO DO
    }

    public detach() {
        removeWheelListener(document, this.onWheelCallback);
        document.removeEventListener('touchstart', $.proxy(this.onTouchStart, this));
        document.removeEventListener('touchmove', $.proxy(this.onTouchMove, this));
    }

    private onFrame() {

        var inc = (this.event.targetScrollY - this.event.scrollY);
        var oldY = this.event.scrollY;

        if (this.autoPilotMode) {
            this.autoScrollTo();
        } else if (this.autoScroll) {
            this.event.scrollY += this.velocity;
        } else if (this.easing) {
            inc *= this.easing;
            this.event.scrollY += inc;
        }

        if (Math.abs(this.event.targetScrollY - this.event.scrollY) > 0.1 || this.autoPilotMode || this.autoScroll) {
            this._dispatch();
        }
    }

    public enableAutoScroll(velocity) {

        this.autoScroll = true;
        this.velocity = velocity;
    }

    public disableAutoScroll() {

        this.autoScroll = false;
    }

    private onWheel(e?) {

        if (!this.autoPilotMode) {
            var deltaY = (e.deltaY == e.deltaY >> 0) ? e.deltaY : e.deltaY * e.deltaY * e.deltaY;
            deltaY *= this.multiplier;
            deltaY = Math.round(deltaY);
            if (deltaY < 0) {
                deltaY = Math.max(deltaY, -100)
            } else {
                deltaY = Math.min(deltaY, 100);
            }
            this._set(deltaY);
        }
    }

    private onTouchStart(e?) {
        this.lastPageX = 0;
        this.lastPageY = 0;
    }

    private onTouchMove(e?) {
        e.preventDefault();

        this.autoPilotMode = false;

        if (this.lastPageX != 0) {
            this._set(
                -(e.targetTouches[0].pageY - this.lastPageY) * this.multiplier
            );
        }

        this.lastPageX = e.targetTouches[0].pageX;
        this.lastPageY = e.targetTouches[0].pageY;
    }
}

class InstructionTrigger {

    private triggerClickCount:number = 3;
    private triggerElapsedTime:number = 20;

    private touchCount:number = 0;
    private clickCount:number = 0;
    private triggerFunction:() => void;
    private wrappedTrigger:() => void;

    constructor(triggerFunction:() => void) {
        this.triggerFunction = triggerFunction;
        this.reset();
    }

    public recordTouch():void {
        if (++this.touchCount >= this.triggerClickCount) {
            this.wrappedTrigger();
        }
    }

    public recordClick():void {
        if (++this.clickCount >= this.triggerClickCount) {
            this.wrappedTrigger();
        }
    }

    public reset():void {
        this.wrappedTrigger = _.once(() => this.trigger());
        this.touchCount = 0;
        this.clickCount = 0;
        TweenLite.delayedCall(this.triggerElapsedTime, this.wrappedTrigger);
    }

    public disable():void {
        TweenLite.killDelayedCallsTo(this.wrappedTrigger);
    }

    private trigger():void {
        TweenLite.killDelayedCallsTo(this.wrappedTrigger);
        this.triggerFunction();
    }
}

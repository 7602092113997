/// <reference path='./SeancesScene.ts'/>
/// <reference path='../../scaffolding/Main.ts'/>
/// <reference path='../../scaffolding/EventBus.ts'/>
/// <reference path='../../scaffolding/Utils.ts'/>
/// <reference path='../InstructionTrigger.ts'/>
/// <reference path='../ProgressCircle.ts'/>
/// <reference path='../title/TitleGenerator.ts'/>
/// <reference path='../title/TitleSequence.ts'/>
/// <reference path='../title/GenreGenerator.ts'/>
/// <reference path='../title/GenreSequence.ts'/>
/// <reference path='../shaders/RGBGlitchShader.ts'/>
/// <reference path='../assets/GridImages.ts'/>

let instructTween;

class SeancesIntroScene extends SeancesScene {
    public static TITLE_SWAP = 'title_swap';
    public static FINALIZE_TITLE = 'finalize_title';

    private titleSequence:TitleSequence;
    private progressCircle:ProgressCircle;
    private glitchPass:ShaderPass;
    private textureLoader:THREE.TextureLoader;

    private isCountdownOver:boolean = false;
    private countdownTargetTime:number = 3;
    private mousePosition:THREE.Vector3;
    private timeline:TimelineMax = new TimelineMax();
    private intensityTween:TweenMax;
    private shiftingTween:TweenMax;

    private texAtlas:TextureAtlas;
    private genreRenderer:LineTextureRenderer;
    private titleRenderer:LineTextureRenderer;
    private backgroundDims:CameraView;
    private titleDims:CameraView;
    private videoBackground:VideoBackground;
    private imageBackground:ImageBackground;
    private staticBackground:Background;

    private clickStartSound:any;
    private clickSuccessSound:any;
    private clickFailSound:any;
    private titleScreenLoop:any;

    private glitchSounds:buzz.sound[];

    private backgroundSwapping:boolean = false;

    private planeImageFactory:PlaneImageFactory;
    private genreSequence:GenreSequence;

    private instructionsElement:JQuery;
    private instructionsVisible:boolean = false;

    private instructionsTrigger:InstructionTrigger;
    private instructionsTween:TweenMax;

    private instructionsLastWidth:number;
    private instructionsLastHeight:number;

    private lastTouchPosition:THREE.Vector2 = new THREE.Vector2(50, 200); // arbitrary values
    private topBar:JQuery = $('.top-bar');
    private bottomBar:JQuery = $('.footer');

    private moveHandler = e => this.mouseMove(e.message);

    private bgImg;

    private bgImages:string[] = [
        "Grid_Image_Bits_of_Life_09.jpg",
        "Grid_Image_Bits_of_Life_10.jpg",
        "Grid_Image_Bits_of_life_13.jpg",
        "Grid_Image_Blind_Girl_06.jpg",
        "Grid_Image_Der_Januskopf_05.jpg",
        "Grid_Image_Der_Januskopf_06.jpg",
        "Grid_Image_Der_Januskopf_07.jpg",
        "Grid_Image_Dream_Woman_02.jpg",
        "Grid_Image_Dream_Woman_021.jpg",
        "Grid_Image_Dream_Woman_04.jpg",
        "Grid_Image_Forbidden_Room_04.jpg",
        "Grid_Image_GBS_01.jpg",
        "Grid_Image_Saint_Devil_Woman_03.jpg",
        "Grid_Image_Saint_Devil_Woman_04.jpg",
        "Grid_Image_Saint_Devil_Woman_051.jpg",
        "Grid_Image_Scorching_Flame_03.jpg",
        "Grid_Image_Tokyo_Ginza_Disctrict_04.jpg",
        "Grid_Image_Tokyo_Ginza_District_03.jpg",
        "Grid_Image_Women_Skeletons_08.jpg",
        "Grid_Image_Women_Skeletons_09.jpg"
    ];


    constructor(progress:ProgressCircle,
                textureLoader:THREE.TextureLoader,
                d:GetExperienceResponse,
                bgDims:CameraView,
                tDims:CameraView,
                planeImageFactory:PlaneImageFactory) {
        super();

        this.mousePosition = new THREE.Vector3(0, 0, 0.5);
        this.textureLoader = textureLoader;

        this.backgroundDims = bgDims;

        this.titleDims = tDims;


        this.progressCircle = progress;
        this.add(this.progressCircle);
        this.planeImageFactory = planeImageFactory;

        if(Main.mobile){
            $(window).bind('resize orientationchange', ()=>this.mobileResize());
            this.mobileResize();
            this.init(d);
        } else {
            this.init(d);
        }
    }


    private init(d){
        this.titleScreenLoop = buzzSoundCdn('/audio/title_screen_loop.mp3').fadeIn(800).play().loop();
        this.clickStartSound = buzzSoundCdn('/audio/click_start.mp3');
        this.clickSuccessSound = buzzSoundCdn('/audio/click_success.mp3');
        this.clickFailSound = buzzSoundCdn("/audio/click_fail.mp3");

        this.glitchSounds = [
            buzzSoundCdn("/audio/Animation_glitch_01.mp3"),
            buzzSoundCdn("/audio/Animation_glitch_02.mp3"),
            buzzSoundCdn("/audio/Animation_glitch_03.mp3"),
            buzzSoundCdn("/audio/Animation_glitch_04.mp3")
        ];

        this.addEventListener('mousedown', e => this.mouseDown(e.message));
        this.addEventListener('touchstart', e => this.mouseDown(e.message));
        this.addEventListener('mousemove', this.moveHandler);
        this.addEventListener('touchmove', this.moveHandler);

        this.addEventListener('mouseup', (e) => this.mouseUp(e.message));
        this.addEventListener('touchend', (e) => this.mouseUp(e.message));

        this.texAtlas = new TextureAtlas(
            12,
            $("#atlasRenderer")
        );

        this.titleRenderer = new LineTextureRenderer(
            'Hoefler Text SC',
            36,
            this.texAtlas
        );

        this.genreRenderer = new LineTextureRenderer(
            'Hoefler Text',
            36,
            this.texAtlas
        );

        this.titleSequence = new TitleSequence(
            this.titleRenderer,
            new TitleGenerator(
                d.template_sequence,
                [d.a, d.b, d.c],
                d.title
            )
        );

        this.genreSequence = new GenreSequence(
            this.genreRenderer,
            new GenreGenerator(
                d.genres,
                Math.floor(d.film_length / 60)
            )
        );
        let genreZ = -375;
        let genreY = -70;
        let titleZ = -175;
        let titleY = 4;
        let imageZ = -375;
        let imageY = 84;


        if(Main.mobile){
            genreZ = -120;
            genreY = -22;

            titleZ = -100;
            titleY = 1.8;

            imageZ = -100;
            imageY = 22;
        }


        this.titleSequence.translateZ(titleZ);
        this.titleSequence.translateY(titleY);
        this.add(this.titleSequence);

     

        this.genreSequence.translateZ(genreZ);
        this.genreSequence.translateY(genreY);
        this.add(this.genreSequence);


        this.addEventListener(NickelScene.TICK_EVENT, () => {
            this.glitchPass.uniforms.fGlobalTime.value += 0.005;
        });


        this.planeImageFactory.createImage('/img/seances_wordmark_xl.png').then((i) => {
            const scale = {x: 1, y: 1, z: 1};
            for (let idx in scale) {
                scale[idx] = scale[idx] * 0.07;
            }
            i.position.setY(imageY);
            i.position.setZ(imageZ);
            i.geometry.scale(scale.x, scale.y, scale.z);
            this.add(i);
        });


        if(!Main.mobile){
            this.videoBackground = new VideoBackground(this.backgroundDims.height, this.backgroundPool);
            this.videoBackground.translateZ(-1000);
            this.add(this.videoBackground);
        } else {
             this.imageBackground = new ImageBackground(this.backgroundDims.height, this.bgImages);
             this.imageBackground.translateZ(-1000);
             this.add(this.imageBackground);
        }

        this.ambientGlitchingTimer();
        this.delayedShiftTitles();

        this.instructionsElement = $('#instructions');

        this.instructionsTrigger = new InstructionTrigger(() => this.instructionsTriggered());
        this.instructionsTween = TweenMax.fromTo(
            this.instructionsElement,
            1,
            {opacity: 0},
            {
                opacity: 0.8,
                onStart: () => {
                    this.instructionsVisible = true;
                    this.updateInstructionsPosition();
                },
                onReverseComplete: () => {
                    this.instructionsVisible = false;
                },
                immediateRender: true,
                paused: true
            });

        instructTween = this.instructionsTween;

        this.addEventListener(Stage.NEXT_SCENE, () => this.instructionsTrigger.disable());

        gaTrackEvent('title_sequence', 'start');
    }

    private instructionsTriggered() {
        this.instructionsTween.timeScale(1);
        this.instructionsTween.play();
    }

    private mouseMove(e:JQueryEventObject) {
        this.updateLastTouchPosition(<UIEvent>e.originalEvent);
    }

    private mouseDown(e:JQueryEventObject) {
        this.updateLastTouchPosition(<UIEvent>e.originalEvent);

        if (this.instructionsTween.progress() > 0.3 && !this.instructionsTween.reversed()) {
            this.instructionsTrigger.reset();
            this.instructionsTween.timeScale(4);
            this.instructionsTween.reverse();
        }

        this.startCountdown();
    }

    private updateLastTouchPosition(e:UIEvent):void {
        if (e instanceof MouseEvent) {
            this.lastTouchPosition.set(e.clientX, e.clientY);
            if (e.type == "mouseup") {
                this.instructionsTrigger.recordClick();
            }
        } else if (e instanceof TouchEvent) {
            this.lastTouchPosition.set(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
            if (e.type === 'touchend') {
                this.instructionsTrigger.recordTouch();
            }
        } else {
            return;
        }

        this.updateInstructionsPosition();
    }

    private updateInstructionsPosition():void {
        const pointer = this.lastTouchPosition;

        if (!this.instructionsLastWidth || this.instructionsLastWidth < 10)
            this.instructionsLastWidth = this.instructionsElement[0].getBoundingClientRect().width;
        if (!this.instructionsLastHeight || this.instructionsLastHeight < 10)
            this.instructionsLastHeight = this.instructionsElement[0].getBoundingClientRect().height;

        const eleW = this.instructionsLastWidth;
        const eleH = this.instructionsLastHeight;

        const topBound = this.topBar.height() + eleH;
        const bottomBound = window.innerHeight - this.bottomBar.height();
        const rightBound = window.innerWidth - eleW;

        if (pointer.y < topBound) {
            pointer.setY(topBound);
        } else if (pointer.y > bottomBound) {
            pointer.setY(bottomBound);
        }

        if (pointer.x > rightBound) {
            pointer.setX(rightBound);
        }

        this.instructionsElement.css('left', pointer.x);
        this.instructionsElement.css('top', pointer.y - eleH);
    }

    private startCountdown() {
        this.clickStartSound.play();
        this.timeline.add(TweenMax.fromTo(
            this.progressCircle,
            this.countdownTargetTime / 16,
            {tweenVar: 0},
            {
                tweenVar: 0.5,
                onUpdate: () => this.updateCountdown(this.progressCircle.tweenVar),
            }));

        this.timeline.add(TweenMax.to(
            this.progressCircle,
            15 * this.countdownTargetTime / 16,
            {
                tweenVar: 1,
                onUpdate: () => this.updateCountdown(this.progressCircle.tweenVar),
                onComplete: () => this.countdownOver()
            }
        ));

        this.intensity = 'lots';
    }

    private mouseUp(e:JQueryEventObject) {

        this.updateLastTouchPosition(<UIEvent>e.originalEvent);

        if (!this.isCountdownOver) {
            this.endCountdown();
        }
    }

    private endCountdown() {

        this.intensity = 'none';
        this.progressCircle.reset();
        this.remove(this.staticBackground);
        this.timeline.clear();
        this.clickStartSound.stop();

        if (this.isCountdownOver) {
            this.clickSuccessSound.play();
            this.intensityTween.kill();
            this.shiftingTween.kill();
            for (let sound in buzz.sounds) {
                buzz.sounds[sound].stop();
            }

            this.dispatchEvent({type: Stage.NEXT_SCENE});
            gaTrackEvent('title_sequence', 'end');

        } else {
            this.shiftingTween.resume();
            this.clickFailSound.play();
        }
    }

    private updateCountdown(progress:number) {

        this.progressCircle.updateProgress(Math.min(progress, 1.00));

        switch (true) {
            case (progress >= 0.25 && progress < 0.50):
                this.intensity = 'lots';
                break;
            case (progress >= 0.50 && progress < 0.75):
                this.intensity = 'crazy';
                if (!this.shiftingTween.paused())
                    this.shiftFinalTitle();
                break;
            case (progress >= 0.75 && progress < 0.90):
                this.intensity = 'frenetic';
                break;
            case (progress >= 0.90 && progress < 1.00):
                this.intensity = 'lots';
                break;
        }

        if (progress >= 0.5 && !this.backgroundSwapping) {
            this.backgroundSwapping = true;
            this.changeStaticBackground();
        }
    }

    private countdownOver() {

        this.isCountdownOver = true;
        this.endCountdown();
    }

    public getPostProcessingPasses() {
        this.glitchPass = new ShaderPass(new RGBGlitchShader());
        this.glitchPass.uniforms.tDiffuseNoise.value = this.textureLoader.load('/img/tex16.png');
        this.glitchPass.uniforms.tDiffuseNoise.value.wrapS = this.glitchPass.uniforms.tDiffuseNoise.value.wrapT = THREE.RepeatWrapping;

        if(Main.mobile){
            return [];
        } else {
            return [this.glitchPass];
        }
    }

    public set intensity(glitch:GlitchIntensity) {
        if (glitch != 'none') {
            this.playGlitchSound();
        }
        switch (glitch) {
            case 'none':
                this.glitchPass.uniforms.fSpeed.value = 0.2;
                this.glitchPass.uniforms.fAmplitude.value = 0;
                if (this.titleSequence) this.titleSequence.children.forEach(i => {
                    if (i instanceof Word)
                        i.material.uniforms.fDistortionIntensity.value = 0.0
                });
                return;
            case 'slight':
                this.glitchPass.uniforms.fSpeed.value = 0.2;
                this.glitchPass.uniforms.fAmplitude.value = 0.04;
                if (this.titleSequence) this.titleSequence.children.forEach(i => {
                    if (i instanceof Word)
                        i.material.uniforms.fDistortionIntensity.value = 0.00025
                });
                return;
            case 'lots':
                this.glitchPass.uniforms.fSpeed.value = 0.4;
                this.glitchPass.uniforms.fAmplitude.value = 0.08;
                if (this.titleSequence) this.titleSequence.children.forEach(i => {
                    if (i instanceof Word)
                        i.material.uniforms.fDistortionIntensity.value = 0.000375
                });
                return;
            case 'crazy':
                this.glitchPass.uniforms.fSpeed.value = 0.6;
                this.glitchPass.uniforms.fAmplitude.value = 0.12;
                if (this.titleSequence) this.titleSequence.children.forEach(i => {
                    if (i instanceof Word)
                        i.material.uniforms.fDistortionIntensity.value = 0.00050
                });
                return;
            case 'frenetic':
                this.glitchPass.uniforms.fSpeed.value = 0.8;
                this.glitchPass.uniforms.fAmplitude.value = 0.16;
                if (this.titleSequence) this.titleSequence.children.forEach(i => {
                    if (i instanceof Word)
                        i.material.uniforms.fDistortionIntensity.value = 0.000625
                });
                return;
        }
    }

    public get intensity():GlitchIntensity {
        switch (this.glitchPass.uniforms.fAmplitude.value) {
            case 0:
                return 'none';
            case 0.04:
                return 'slight';
            case 0.08:
                return 'lots';
            case 0.12:
                return 'crazy';
            case 0.16:
                return 'frenetic';
        }
    }

    private changeStaticBackground() {
        this.textureLoader.load(GridImages.randomImage(), t => {
            t.format = THREE.RGBFormat;

            this.staticBackground = new Background(t, new THREE.Box2(
                new THREE.Vector2(0, 0),
                new THREE.Vector2(GridImages.width(), GridImages.height())
            ), this.backgroundDims.height);

            this.staticBackground.translateZ(-950);
            this.add(this.staticBackground);
            setTimeout((b) => this.remove(b), 50, this.staticBackground);
            setTimeout(() => this.backgroundSwapping = false, Math.floor(Math.random() * 750));

        });
    }

    private ambientGlitchingTimer():void {
        if (this.glitchPass && this.intensity === 'none') {
            this.intensityTween = TweenMax.delayedCall(
                Math.random() * 9 + 1,
                () => {
                    if (this.glitchPass && this.intensity === 'none')
                        this.intensity = 'slight';
                    this.ambientGlitchingTimer();
                });
        } else if (this.glitchPass && this.intensity === 'slight') {
            this.intensityTween = TweenMax.delayedCall(
                (Math.sin(2 * Math.PI * Math.random()) / 5 + 1),
                () => {
                    if (this.glitchPass)
                        this.intensity = 'none';
                    this.ambientGlitchingTimer();
                });
        } else {
            this.intensityTween = TweenMax.delayedCall(1, () => this.ambientGlitchingTimer());
        }
        return;
    }

    private playGlitchSound():void {
        _.sample<buzz.sound>(this.glitchSounds).play();
    }

    private shiftTitles():void {
        this.children.forEach(c => TweenMax.delayedCall(Math.random(), () => c.dispatchEvent({type: SeancesIntroScene.TITLE_SWAP})));
        return;
    }

    private delayedShiftTitles():void {
        this.shiftTitles();
        this.shiftingTween = TweenMax.delayedCall(Math.random() * 9 + 1, () => this.delayedShiftTitles());
    }

    private shiftFinalTitle():void {
        this.shiftingTween.pause();
        this.children.forEach(c => c.dispatchEvent({type: SeancesIntroScene.FINALIZE_TITLE}));
    }

    private mobileResize(){
        if (Main.sWidth > Main.sHeight) {
            $('#container').removeClass('hide');
            $('.rotate').removeClass('show');
        } else {
            $('#container').addClass('hide');
            $('.rotate').addClass('show');
        }
    }
}

type GlitchIntensity = 'none' | 'slight' | 'lots' | 'crazy' | 'frenetic';


class VideoCard extends PhotoCard{
    
    private renderingVideo:HTMLVideoElement;
    private source:HTMLSourceElement;
    private imgW:number = 256;
    private imgH:number = 144;
    private tex:THREE.Texture;
    private playing:boolean = false;
    private initTime:any;
    private updateFrame:boolean = false;
    constructor(textureLoader:THREE.TextureLoader, img:string) {

        super(textureLoader, img);
    }

    public tick(){

        super.tick();
    }

    public show(){

        super.show();

        this.playing = true;
        this.renderingVideo.play();
    }

    public hide(center?){

        super.hide(center)

        this.playing = false;
        this.renderingVideo.pause();
    }

    public swapImage(){

        //override
    }

    public getMesh():any {

        if(this.renderingVideo){
            return;
        }
        
        this.renderingVideo = document.createElement( 'video' );
        this.renderingVideo.width = this.imgW;
        this.renderingVideo.height = this.imgH;
        this.renderingVideo.loop = true;
        this.renderingVideo.src = this.imgSrc;

        this.renderingVideo.load();

        this.tex = new THREE.VideoTexture(this.renderingVideo);
        this.tex.minFilter = THREE.LinearFilter;
        this.tex.magFilter = THREE.LinearFilter;
        this.tex.format = THREE.RGBFormat;
        const mat = new THREE.MeshBasicMaterial({'map': this.tex, 'transparent': true});
        const geo = new THREE.PlaneGeometry(
            this.cardWidth,
            this.cardHeight
        );
        return new THREE.Mesh(geo, mat);
    }
}
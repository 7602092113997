class PlayOverlay extends Component{
    
    constructor(container, data, del){

        super(container, data, del);

        this.setContent(Main.templates.find('.play').clone());

        console.log("new play overlay")
    }
}
class PlaneImageFactory {
    private loader:THREE.ImageLoader;

    constructor(loader:THREE.ImageLoader) {
        this.loader = loader;
    }

    public createImage(url:string):Promise<PlaneImage> {
        return new Promise((resolve:(image:PlaneImage) => void, reject) => {
            this.loader.load(url, (image:HTMLImageElement) => {
                let canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;
                canvas.getContext('2d').drawImage(image, 0, 0);

                const mat = new THREE.MeshBasicMaterial({
                    'map': new THREE.CanvasTexture(canvas),
                    'transparent': true
                });
                const geo = new THREE.PlaneGeometry(
                    image.width,
                    image.height
                );
                resolve(new THREE.Mesh(geo, mat));
            }, null, (err) => {
                reject(err);
            });
        });
    }
}

class PlaneImage extends THREE.Mesh {
}

/// <reference path='../scaffolding/EventBus.ts'/>
class BandwidthDetector {

    public file:string = "https://upload.wikimedia.org/wikipedia/commons/0/03/Mountain_Bluebird.jpg";
    public size:number = 298475; // In Bytes
    public downloadTimeout:number;

    public static GOT_SPEED:string = "gotspeed";
    public static LOW:string = "low";
    public static MEDIUM:string = "medium";
    public static HIGH:string = "high";

    public getSpeed() {
        var startTime:any;
        var endTime:any;
        var scope = this;
        var img = new Image();
        img.onload = () => {
            endTime = (new Date()).getTime();
            scope.results(startTime, endTime);
        };

        img.onerror = () => console.warn("There was an error downloading the image");

        startTime = (new Date()).getTime();
        var fixCaching = "?cb=" + startTime;//cache busting
        img.src = this.file + fixCaching;

        clearTimeout(this.downloadTimeout);
        this.downloadTimeout = setTimeout(() => {
            EventBus.dispatchEvent(BandwidthDetector.GOT_SPEED, BandwidthDetector.LOW);
        }, 2500);

    }

    public results(startTime, endTime) {
        clearTimeout(this.downloadTimeout);
        var duration = (endTime - startTime) / 1000;
        var sizeInBits = this.size * 8;
        var speedBps = parseInt((sizeInBits / duration).toFixed(2));
        var speedKbps = parseInt((speedBps / 1024).toFixed(2));
        var speedMbps = parseInt((speedKbps / 1024).toFixed(2));
        var speedLevel;

        if (speedKbps < 2400) {
            speedLevel = BandwidthDetector.LOW;
        } else if (speedKbps < 8000) {
            speedLevel = BandwidthDetector.MEDIUM;
        } else{
            speedLevel = BandwidthDetector.HIGH;
        }

        EventBus.dispatchEvent(BandwidthDetector.GOT_SPEED, speedLevel);
    }

}


class GridImages {
    private static urls:string[] = [
        "grid_image_bits_of_life_01.jpg",
        "grid_image_bits_of_life_02.jpg",
        "grid_image_bits_of_life_03.jpg",
        "grid_image_bits_of_life_04.jpg",
        "grid_image_bits_of_life_05.jpg",
        "grid_image_bits_of_life_06.jpg",
        "grid_image_bits_of_life_07.jpg",
        "grid_image_bits_of_life_08.jpg",
        "grid_image_bits_of_life_09.jpg",
        "grid_image_bits_of_life_10.jpg",
        "grid_image_bits_of_life_11.jpg",
        "grid_image_bits_of_life_12.jpg",
        "grid_image_bits_of_life_13.jpg",
        "grid_image_bits_of_life_14.jpg",
        "grid_image_blind_girl_01.jpg",
        "grid_image_blind_girl_02.jpg",
        "grid_image_blind_girl_03.jpg",
        "grid_image_blind_girl_04.jpg",
        "grid_image_blind_girl_05.jpg",
        "grid_image_blind_girl_06.jpg",
        "grid_image_blind_girl_07.jpg",
        "grid_image_blind_girl_08.jpg",
        "grid_image_bmm_01.jpg",
        "grid_image_cc_01.jpg",
        "grid_image_dalagang_bukid_01.jpg",
        "grid_image_dalagang_bukid_02.jpg",
        "grid_image_der_januskopf_01.jpg",
        "grid_image_der_januskopf_02.jpg",
        "grid_image_der_januskopf_03.jpg",
        "grid_image_der_januskopf_04.jpg",
        "grid_image_der_januskopf_05.jpg",
        "grid_image_der_januskopf_06.jpg",
        "grid_image_der_januskopf_07.jpg",
        "grid_image_dracula_halala_01.jpg",
        "grid_image_dream_woman_01.jpg",
        "grid_image_dream_woman_02.jpg",
        "grid_image_dream_woman_03.jpg",
        "grid_image_dream_woman_04.jpg",
        "grid_image_foc_alternate_01.jpg",
        "grid_image_foc_alternate_02.jpg",
        "grid_image_foc_alternate_03.jpg",
        "grid_image_foc_alternate_04.jpg",
        "grid_image_foc_alternate_05.jpg",
        "grid_image_forbidden_room_01.jpg",
        "grid_image_forbidden_room_04.jpg",
        "grid_image_forbidden_room_alt_01.jpg",
        "grid_image_forbidden_room_alt_02.jpg",
        "grid_image_forbidden_room_alt_03.jpg",
        "grid_image_gabriela_01.jpg",
        "grid_image_gabriela_02.jpg",
        "grid_image_gabriela_03.jpg",
        "grid_image_gabriela_04.jpg",
        "grid_image_gabriela_05.jpg",
        "grid_image_gabriela_06.jpg",
        "grid_image_gabriela_07.jpg",
        "grid_image_gbs_01.jpg",
        "grid_image_gbs_02.jpg",
        "grid_image_httab_01.jpg",
        "grid_image_lotm_05.jpg",
        "grid_image_obw_alternate_01.jpg",
        "grid_image_obw_alternate_02.jpg",
        "grid_image_obw_alternate_03.jpg",
        "grid_image_obw_alternate_04.jpg",
        "grid_image_poto_poto_06.jpg",
        "grid_image_poto_poto_07.jpg",
        "grid_image_potopoto_01.jpg",
        "grid_image_potopoto_02.jpg",
        "grid_image_potopoto_03.jpg",
        "grid_image_potopoto_04.jpg",
        "grid_image_potopoto_05.jpg",
        "grid_image_red_wolves_02.jpg",
        "grid_image_red_wolves_03.jpg",
        "grid_image_red_wolves_04.jpg",
        "grid_image_red_wolves_05.jpg",
        "grid_image_red_wolves_06.jpg",
        "grid_image_red_wolves_07.jpg",
        "grid_image_red_wolves_08.jpg",
        "grid_image_red_wolves_09.jpg",
        "grid_image_redwolves_01.jpg",
        "grid_image_saint_devil_woman_01.jpg",
        "grid_image_saint_devil_woman_02.jpg",
        "grid_image_saint_devil_woman_03.jpg",
        "grid_image_saint_devil_woman_04.jpg",
        "grid_image_saint_devil_woman_05.jpg",
        "grid_image_scorching_flame_01.jpg",
        "grid_image_scorching_flame_02.jpg",
        "grid_image_scorching_flame_03.jpg",
        "grid_image_scorching_flame_04.jpg",
        "grid_image_scorching_flame_05.jpg",
        "grid_image_scorching_flame_06.jpg",
        "grid_image_scorching_flame_07.jpg",
        "grid_image_scout_day_01.jpg",
        "grid_image_scout_day_02.jpg",
        "grid_image_soam_01.jpg",
        "grid_image_tokyo_ginza_district_01.jpg",
        "grid_image_tokyo_ginza_district_02.jpg",
        "grid_image_tokyo_ginza_district_03.jpg",
        "grid_image_tokyo_ginza_district_04.jpg",
        "grid_image_tokyo_ginza_district_05.jpg",
        "grid_image_tokyo_ginza_district_06.jpg",
        "grid_image_trumpet_island_01.jpg",
        "grid_image_trumpet_island_02.jpg",
        "grid_image_trumpet_island_03.jpg",
        "grid_image_trumpet_island_04.jpg",
        "grid_image_women_skeletons_01.jpg",
        "grid_image_women_skeletons_02.jpg",
        "grid_image_women_skeletons_03.jpg",
        "grid_image_women_skeletons_04.jpg",
        "grid_image_women_skeletons_05.jpg",
        "grid_image_women_skeletons_06.jpg",
        "grid_image_women_skeletons_07.jpg",
        "grid_image_women_skeletons_08.jpg",
        "grid_image_women_skeletons_09.jpg",
    ];

    private static preloadedImages:string[] = [];

    private static urlPrefix:string = '/img/grid/';

    public static PRELOAD_BATCH_SIZE:number = 10;

    public static randomImage():string {
        if (GridImages.preloadedImages.length < 5) {
            GridImages.preload(GridImages.PRELOAD_BATCH_SIZE);
        }
        if (GridImages.preloadedImages.length === 0) {
            return CDN_GLOBAL_PREFIX + GridImages.urlPrefix + _.sample(GridImages.urls);
        }
        return GridImages.preloadedImages.pop();
    }

    public static width():number {
        return 1920;
    }

    public static height():number {
        return 1080;
    }


    public static preload(quantity?:number) {
        let num = quantity;
        if(Main.mobile){
            num = num / 2;
        }
        const imageLoader:THREE.ImageLoader = new THREE.ImageLoader();
        imageLoader.setCrossOrigin('anonymous');
        const images = num ? _.sample(GridImages.urls, num) : GridImages.urls;
        images.forEach(url => {
            const fullUrl = CDN_GLOBAL_PREFIX + GridImages.urlPrefix + url;
            let loadedImages = imageLoader.load(fullUrl, () => this.preloadedImages.push(fullUrl));
        });
    }
}

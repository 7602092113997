/// <reference path='../../seances/shaders/MoveItShader.ts'/>
/// <reference path='./LineTextureRenderer.ts'/>

const OFFSET_PRECISION:number = 10.0;

class Word extends THREE.Mesh {
    /**
     * This doesn't shadow the material property on THREE.Mesh, it declares a narrower type constraint
     */
    public material:THREE.ShaderMaterial;

    private static DEFAULT_ALPHA:number = 0.4;

    public static makeWord(data:WordParameters, renderer:LineTextureRenderer):Word {
        let extra:string[] = [];
        if (data.smallCaps) {
            extra.push('small-caps');
        }
        if (data.italic) {
            extra.push('italic');
        }

        let lineTexData = renderer.makeLineTexture(data.text, extra);

        const alpha = typeof data.alpha === 'number' ? data.alpha : Word.DEFAULT_ALPHA;

        const shaderParams:MoveItShaderParams = {
            timeOffset: Utils.randomFromInterval(0, 360) / (2 * Math.PI),
            driftAnimation: data.shaderMotion,
            driftDisplacement: data.lineHeight
        };

        if (data.colour) {
            // tasty
            shaderParams.colour = (<NColor>data.colour).toVector4(alpha);
            shaderParams.driftRange = new THREE.Vector3(2, 1, 1);
            shaderParams.driftDisplacement = 1.0;
        } else {
            shaderParams.colour = new THREE.Vector4(1.0, 1.0, 1.0, alpha);
        }

        if (data.distanceFadeOut) {
            shaderParams.distanceFadeOut = true;
        }

        const material = new THREE.ShaderMaterial(
            new MoveItShader(
                lineTexData.map,
                shaderParams
            )
        );

        material.uniforms.vUvBounds.value = [
            lineTexData.uvs[0].x, lineTexData.uvs[0].y,
            lineTexData.uvs[2].x, lineTexData.uvs[2].y
        ];

        const textHeight = data.lineHeight;
        const textWidth = textHeight * lineTexData.textAspectRatio;

        const geometry:THREE.Geometry = new THREE.PlaneGeometry(
            textWidth,
            textHeight
        );
        geometry.computeBoundingBox();

        geometry.faceVertexUvs[0][0] = [lineTexData.uvs[0], lineTexData.uvs[1], lineTexData.uvs[3]];
        geometry.faceVertexUvs[0][1] = [lineTexData.uvs[1], lineTexData.uvs[2], lineTexData.uvs[3]];
        geometry.uvsNeedUpdate = true;

        const line = new Word(geometry, material);
        line.name = data.text;

        if (data.userData) {
            line.userData = data.userData;
        }

        if (data.yRange) {
            const verticalShift = Utils.randomFromInterval(
                    -data.yRange * OFFSET_PRECISION,
                    data.yRange * OFFSET_PRECISION
                ) / OFFSET_PRECISION;
            line.translateY(verticalShift);
        }

        line.addEventListener(NickelScene.TICK_EVENT, () => {
            line.material.uniforms.fTime.value += 0.01;
            if (data.worldTick) {
                data.worldTick();
            }
        });

        return line;
    }

    public width():number {
        return this.geometry.boundingBox.max.x - this.geometry.boundingBox.min.x;
    }
}

interface WordParameters {
    text:string;
    lineHeight:number;
    colour?:THREE.Color;
    alpha?:number;
    yRange?:number;
    shaderMotion?:boolean;
    worldTick?:(event?:any) => void
    userData?:any;
    smallCaps?:boolean;
    italic?:boolean;
    distanceFadeOut?:boolean;
}

class Background extends THREE.Mesh {
    constructor(map:THREE.Texture, size:THREE.Box2, cameraHeight:number) {
        const material = new THREE.MeshLambertMaterial({
            map: map,
            depthWrite: false
        });

        const backgroundHeight = cameraHeight * 1.1;
        const backgroundRatio = size.max.x / size.max.y;
        const backgroundWidth = backgroundHeight * backgroundRatio;

        const geometry = new THREE.PlaneGeometry(backgroundWidth, backgroundHeight);

        // TODO: File bug with JetBrains about this
        //noinspection TypeScriptValidateTypes
        super(geometry, material);
        this.name = 'background';
    }
}

class MoveItShader implements THREE.ShaderMaterialParameters {
    public transparent:boolean = true;
    public depthWrite:boolean = false;

    constructor(texture:THREE.Texture, params?:MoveItShaderParams) {
        this.uniforms.tTexture.value = texture;
        if (params) {
            if (params.timeOffset) this.uniforms.fTime.value = params.timeOffset;
            if (params.driftAnimation) this.defines.ANIMATE_DRIFT = true;
            if (params.driftDisplacement) this.uniforms.fDisplacement.value = params.driftDisplacement;
            if (params.driftRange) this.uniforms.vRange.value = params.driftRange;
            if (params.distanceFadeOut) this.defines.DISTANCE_FADEOUT = true;
            if (params.colour) this.uniforms.vColour.value = params.colour;

        }
    }

    public uniforms = {
        fTime: {type: "f", value: 0.1},
        tTexture: {type: "t", value: null},
        fDisplacement: {type: "f", value: 0.25},
        vColour: {type: "v4", value: new THREE.Vector4(1.0, 1.0, 1.0, 0.4)},
        vRange: {type: "v3", value: new THREE.Vector3(1.0, 1.0, 4.0)},
        vUvBounds: {type: "Matrix2fv", value: []},
        fDistortionIntensity: {type: "f", value: 0.000} // reasonable amount is 0.00025
    };

    public defines = {
        PI: Math.PI,
        ANIMATE_DRIFT: false,
        DISTANCE_FADEOUT: false
    };

    public vertexShader:string = `
uniform float fTime;
uniform float fDisplacement;
uniform vec3 vRange;

varying vec2 vUv;
#ifdef DISTANCE_FADEOUT
varying float fZPos;
#endif

mat3 getRotationMatrix(float tick)
{
    return mat3(
        cos(tick) * vRange.x,0,0,
        0,sin(tick) * vRange.y,0,
        0,0,cos(tick) * vRange.z
    );
}

void main()
{
    vUv = uv;

    vec3 displaced =
#ifdef ANIMATE_DRIFT
    getRotationMatrix(fTime) * vec3(fDisplacement) + position;
#else
    position;
#endif

#ifdef DISTANCE_FADEOUT
    fZPos = (projectionMatrix * modelViewMatrix * vec4(displaced, 1.0)).z;
#endif

    gl_Position = projectionMatrix * modelViewMatrix * vec4(displaced, 1.0);

}
`;

    public fragmentShader:string = `
uniform vec4 vColour;
uniform mat2 vUvBounds;
uniform sampler2D tTexture;
uniform float fTime;
uniform float fDistortionIntensity;

varying vec2 vUv;

#ifdef DISTANCE_FADEOUT
varying float fZPos;

float sinh(float var) {
    return (exp(var) - exp(-var)) / float(2);
}

float cosh(float var) {
    return (exp(var) + exp(-var)) / float(2);
}

float tanh(in float var) {
    return sinh(var)/cosh(var);
}
#endif

void main()
{
    float normY = (vUv.y - vUvBounds[0].y)/(vUvBounds[1].y - vUvBounds[0].y);
    vec4 tex = texture2D(
        tTexture,
        vec2(
            clamp(
                vUv.x + (sin((normY + fTime/float(4)) * PI * float(20.0)) * fDistortionIntensity),
                vUvBounds[0].x,
                vUvBounds[1].x
            ),
            vUv.y
        )
    );
    
    gl_FragColor = vec4(
        tex.r * vColour.r,
        tex.g * vColour.g,
        tex.b * vColour.b,
        tex.a * vColour.a
    );
#ifdef DISTANCE_FADEOUT
    gl_FragColor.a *= clamp(tanh((float(210) - fZPos)/float(40)), 0.4, 1.0);
#endif
}
`;
}

interface MoveItShaderParams {
    timeOffset?:number;
    driftAnimation?:boolean;
    driftDisplacement?:number;
    driftRange?:THREE.Vector3;
    distanceFadeOut?:boolean;
    colour?:THREE.Vector4;
}

/// <reference path='../VideoPlayer.ts'/>
/// <reference path='./PlayOverlay.ts'/>

class InstallationVideoPlayer extends VideoPlayer {

    public socket:any;
    public overlay:any;
    private expId:string;

    constructor(container, data, delegate) {

        super(container, data, delegate);

        this.socket = io(Main.socket);
        this.socket.on('connect', (evt)=>this.connectedToSocket(evt));
        this.socket.on('error', (evt)=>this.connectionError(evt));
        this.socket.on('reconnect', (evt)=>this.connectedToSocket(evt));
        this.socket.on('disconnect', (evt)=>this.disconnectedFromSocket(evt));
        this.socket.on('reset', (evt)=>this.reset(evt));

        this.socket.on('getReady', (d)=>this.getReady());
        this.socket.on('loadFilm', (d)=>this.loadVideo(d));
        this.socket.on('playFilm', ()=>this.playVideo());

        this.autoplay = false;

        this.overlay = new PlayOverlay($("#container"), {}, this);

        this.showMe();
    }

    private getReady() {

        console.log("get ready");
        
        TweenMax.delayedCall(25, ()=>{
            this.overlay.showMe();
        });
    }

    public playVideo(){

        //if we don't have a video loaded, get one from the db
        if(!this.videoLink){
            this.getVideo();
        }else{
            this.player.show();
            this.player.play();
            this.overlay.hideMe();            
            // this.data.api.moveExperienceToGraveyard(this.expId);
        }
    }

    private getVideo(){

        this.data.api.getExperience()
        .then((e) => e['data'])
        .then((d) => {
            
            this.loadVideo({
                src:d.mp4_url,
                expId:d.id
            });
            this.playVideo();
        });
    }

    public loadVideo(data:VideoParams) {

        this.videoLink = this.getVideoLink(data.src);
        this.expId = data.expId;

        if (data.poster) {
            this.videoPoster = data.poster;
        }

        if (!this.initialized) {
            this.init();
        } else {
            this.setSrc();
        }
    }

    public getVideoLink(path) {

        let src = "/vid/installation/" + path.split("/")[5];
        return src;
    }

    private reset(evt) {
        location.reload();
    }

    private connectionError(evt) {

        console.log("Socket Connection Error");
        console.log(evt);
    }

    private connectedToSocket(evt) {

        console.log("Socket Connection Successful");

        this.socket.emit('joinRoom', {'roomName': Main.room});
    }

    private disconnectedFromSocket(evt) {

        console.log("Disconnected from Socket");
    }
}

/// <reference path='../scenes/MultiTouchInterfaceScene.ts'/>
/// <reference path='./InstallationStage.ts'/>

class InstallationInterfaceStage extends InstallationStage {
    protected api:SeancesApi;

    private titleScreenLoop:any;
    
    constructor(api:SeancesApi, params?:StageParams) {
        super(params);
        this.api = api;
        
        this.socket.on('initInterface', ()=> {
            
            this.startInstallationScene().then(e => this.run());

            this.titleScreenLoop = buzzSoundCdn('/audio/title_screen_loop.mp3', 0).fadeTo(40, 2000).play().loop();
        })

        this.socket.on('getReady', ()=>{
            let scene = <MultiTouchInterfaceScene>this.currentScene;
            scene.resolveTitle();
            this.titleScreenLoop.fadeOut(25000);
        })

        this.socket.on('playFilm', (evt)=>this.reset(evt));
    }

    public startInstallationScene():Promise<void> {

        const backgroundDims = this.calculateCameraView(-1000);

        return this.api.getExperience()
            .then((e) => e['data'])
            .then((d) => {

                this.socket.emit('loadFilm', {
                    'src': d.mp4_url,
                    'expId':d.id
                });

                this.changeScene(new MultiTouchInterfaceScene(
                    this.textureLoader,
                    d,
                    backgroundDims
                ));
            });
    }
}

/// <reference path='../../scaffolding/Main.ts'/>
/// <reference path='../../scaffolding/EventBus.ts'/>
/// <reference path='../../scaffolding/Utils.ts'/>
/// <reference path='../../scaffolding/Component.ts'/>
/// <reference path='./GraveyardTitle.ts'/>

class Graveyard extends Component {
    private atPage: number = 1;
    private totalPages: number;
    private titleArray: GraveyardTitle[] = [];
    private contentDiv: any;
    private api: any;
    private bgImg;
    private triggerPadding:number = 20;
    private topCutoff:number = -1000;
    private gettingPage:boolean = false;
    private sHeight:number;
    private sWidth:number;
    private clearInterval:number;
    private atY:number;
    private activeTitle:GraveyardTitle;
    private bgImage:HTMLImageElement;

    protected bgVideoPool:string[] = [
        'bol_1g.mp4',
        'db_1g.mp4',
        'dh_1.mp4',
        'dh_2.mp4',
        'dh_3g.mp4',
        'dj_1g.mp4',
        'dj_2g.mp4',
        'dj_3g.mp4',
        'foac_1.mp4',
        'fr_1.mp4',
        'gl_1.mp4',
        'jk_1.mp4',
        'pp_1g.mp4',
        'rausch_1.mp4',
        'sdw_1.mp4',
        'sdw_2g.mp4',
        'sdw_3g.mp4',
        'sdw_4g.mp4',
        'tgd_1.mp4',
        'ti_1.mp4'
    ];

    private bgImages:string[] = [
        "Grid_Image_Bits_of_Life_09.jpg",
        "Grid_Image_Bits_of_Life_10.jpg",
        "Grid_Image_Bits_of_life_13.jpg",
        "Grid_Image_Blind_Girl_06.jpg",
        "Grid_Image_Der_Januskopf_05.jpg",
        "Grid_Image_Der_Januskopf_06.jpg",
        "Grid_Image_Der_Januskopf_07.jpg",
        "Grid_Image_Dream_Woman_02.jpg",
        "Grid_Image_Dream_Woman_021.jpg",
        "Grid_Image_Dream_Woman_04.jpg",
        "Grid_Image_Forbidden_Room_04.jpg",
        "Grid_Image_GBS_01.jpg",
        "Grid_Image_Saint_Devil_Woman_03.jpg",
        "Grid_Image_Saint_Devil_Woman_04.jpg",
        "Grid_Image_Saint_Devil_Woman_051.jpg",
        "Grid_Image_Scorching_Flame_03.jpg",
        "Grid_Image_Tokyo_Ginza_Disctrict_04.jpg",
        "Grid_Image_Tokyo_Ginza_District_03.jpg",
        "Grid_Image_Women_Skeletons_08.jpg",
        "Grid_Image_Women_Skeletons_09.jpg"
    ];

    private pageData:any = [];
    private bgVideo:JQuery;
    private scrollY:number = 0;
    private background:JQuery;
    private graveyardCountDiv:JQuery;
    private graveCount:JQuery;
    private hasAnimated: boolean = false;

    constructor(container:JQuery, data:any, delegate:any) {
        super(container, data, delegate);
        
        this.setContent(Main.templates.find('.graveyard').clone());
        
        this.api = this.data.api;

        this.contentDiv = this.content.find('.content');
        this.contentDiv.bind('click', (e)=>this.closeTitle(e));

        this.background = this.content.find('.bg');
        this.bgVideo = this.content.find('.graveyardBgVideo');
        this.graveyardCountDiv = this.content.find('.graveyardCount');
        this.graveCount = this.content.find('.number');

        this.resize();
        
        this.content.on('scroll', ()=>this.checkScroll());

        if(!Main.mobile){
            this.bgVideo.on('ended', ()=>this.setBackground());    
            this.setBackground();
        }else{
            this.setBackgroundImage();
        }
    }

    private checkScroll(){

        this.atY = this.content.scrollTop();
        if(this.atY + this.content.innerHeight() >= this.content[0].scrollHeight - this.triggerPadding && !this.gettingPage) {
            this.getData();
        }
    }

    private clearHidden(){

        let removed = 0;
        let currentTop = this.content.scrollTop();
        var height;

        var i = this.titleArray.length;
        while (i--) {

            let item = this.titleArray[i];
            height = item.content.height();
            let offset = item.content.offset().top;

            if(offset < this.topCutoff){
                item.killMe();
                if(this.activeTitle == item){
                    this.activeTitle = undefined;
                }
                this.titleArray.splice(i, 1);
                removed++;
            }
        }

        let newTop = currentTop+(removed * height);
        this.content.scrollTop(newTop);
    }

    public resize(){

        this.sWidth = $(window).width();
        this.sHeight = $(window).height();

        let dims = Utils.fitToContainer({
            'containerWidth':this.sWidth,
            'containerHeight':this.sHeight,
            'contentWidth':1920,
            'contentHeight':1080,
            'scaleMode':'proportionalOutside',
            'vAlign':'center',
            'hAlign':'center'
        });

        this.background.css(dims);
    }

    public setBackground(){

        let src = this.getRandomVideo();
        this.bgVideo.attr('src', src);
        this.bgVideo.one('canplaythrough', ()=>{
            this.getData();
            this.getGraveyardCount();
        })
        let el = <HTMLVideoElement>this.bgVideo[0];
        el.load();
        el.play();

    }

    public setBackgroundImage(){

        this.bgImage = new Image();
        this.bgVideo.remove();
        this.bgImage.onload = ()=>{
            this.content.find('.bg').css('background:url('+imagePath+')')
            // this.background.append(this.bgImage);
            this.getData();
        };
        let imagePath = this.getRandomImage()
        this.bgImage.src = imagePath;
    }

    public getData(){

        this.gettingPage = true;

        if (this.totalPages && this.atPage > this.totalPages) {
            this.atPage = 1;
        }
        this.api.getExperienceGraveyard(this.atPage, this.data.expId)
            .then((e) => {

                this.totalPages = e['total_pages'];
                this.clearHidden();
                this.addTitles(e['data']);
                this.pageData[this.atPage-1] = e['data'];

                this.gettingPage = false;
                this.atPage++;
                
                if(!this.onStage){
                    this.showMe();
                }
            },
            (e)=>{
                let data = _.sample(this.pageData);
                this.addTitles(data);

                this.gettingPage = false;
                this.atPage++;

            });
    }

    public getGraveyardCount(){
       this.api.getGraveyardCount()
           .then((e) => {
               let num =  e.current.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
               this.graveCount.html(" " + num)
           });
    }

    public showCounter(){
        console.log(this.graveCount);
        this.graveyardCountDiv.show();
    }

    public addTitles(titles){

        for (var  i = 0; i < titles.length; i++){
            let data = titles[i];
            if (data.experience_id == this.data.expId &&!this.hasAnimated){
                data.animateIn = true;
                this.hasAnimated = true;
            }

            let movieTitle = new GraveyardTitle(this.contentDiv, data, this);
            movieTitle.on(GraveyardTitle.OPEN_TITLE, (title)=>this.openTitle(title), this);
            movieTitle.on(GraveyardTitle.CLOSE_TITLE, (title)=>this.closeTitle(title), this);
            this.titleArray.push(movieTitle);

        }
    }

    private openTitle(title){

        if(this.activeTitle){
            this.activeTitle.closeInfo();
        }

        this.activeTitle = title;
        this.activeTitle.openInfo();

        this.content.addClass('lightbox');
    }

    private closeTitle(title){

        this.content.removeClass('lightbox');
        this.showCounter();
        if(this.activeTitle){
            this.activeTitle.closeInfo();
            this.activeTitle = undefined;    
        }
    }

    private getRandomVideo():string {
        return CDN_GLOBAL_PREFIX + '/vid/backgrounds/' + _.sample(this.bgVideoPool);
    }

    private getRandomImage():string {
        return CDN_GLOBAL_PREFIX + '/img/bg_img/' + _.sample(this.bgImages);
    }
}

interface GraveyardData{
    api:SeancesApi;
    expId?:string;
}

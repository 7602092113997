/// <reference path='./scaffolding/Main.ts'/>
/// <reference path='./scaffolding/three/NickelGroup.ts'/>
/// <reference path='./scaffolding/three/NickelScene.ts'/>
/// <reference path='./seances/VideoPlayer.ts'/>
/// <reference path='./seances/SeancesApi.ts'/>
/// <reference path='./seances/scenes/SeancesIntroScene.ts'/>
/// <reference path='./seances/scenes/IntroSceneNoThree.ts'/>
/// <reference path='./seances/scenes/AboutScene.ts'/>
/// <reference path='./seances/scenes/CreditsScene.ts'/>
/// <reference path='./seances/stages/SeancesStage.ts'/>
/// <reference path='./seances/stages/InstallationInterfaceStage.ts'/>
/// <reference path='./seances/installation/InstallationVideoPlayer.ts'/>
/// <reference path='./seances/installation/InstallationGraveyard.ts'/>
/// <reference path='./seances/installation/InstallationControls.ts'/>
/// <reference path='./seances/stages/MobileStage.ts'/>
/// <reference path='./seances/Header.ts'/>
/// <reference path='./seances/Footer.ts'/>
/// <reference path='./seances/graveyard/Graveyard.ts'/>

Promise.config({
    warnings: false,
    longStackTraces: true
});

type Uuid = string;
const api = new SeancesApi(window.fetch.bind(window), '/');
const container:JQuery = $("#container");

let CDN_GLOBAL_PREFIX:string = '';

let header:Header;
let footer:Footer;
let credit:CreditsScene;
let about:AboutScene;
let stage;

let cfg = <WebFont.Config>{
    custom: <WebFont.Custom>{
        families: [
            'Hoefler Text:n4,i4,n7,i7',
            'Hoefler Text SC',
            '7Sins',
            'adelle sans:n4'
        ]
    }
};

let fontPromise = new Promise((resolve, reject) => {
    cfg.active = () => resolve(true);
    cfg.inactive = () => reject(new Error('unable to load fonts'));
});

WebFont.load(cfg);

Promise.all([fontPromise, window.fetch('/config')])
    .then(vals => (<Response>vals[1]).json())
    .then((config) => {
        new Main(config);

        page('/', () => {
            initSeancesScene();
        });

        page('/skipIntro', () => {
            initSeancesScene(true);
        });

        page('/graveyard', () => {
            initSeancesScene(true, true);
        });

        page('/installation/interface/:room', req => {
            Main.room = req.params.room;
            let stage = new InstallationInterfaceStage(api, {
                container: container,
                enableGui: false,
                enableStats: false
            });
        });

        page('/installation/player/:room', req => {
            Main.room = req.params.room;
            let stage = new InstallationVideoPlayer(container, {api:api}, this);
        });

        page('/installation/graveyard/:room', req => {
            Main.room = req.params.room;
            let stage = new InstallationGraveyard(container, {api:api}, this);
        });

        page('/installation/controls/:room', req => {
            Main.room = req.params.room;
            let controls = new InstallationControls(container, {}, null);
        });

        page(); // DISPATCH OUR ROUTING
    });

function initSeancesScene(skipIntro:boolean = false, graveyard:boolean = false) {
    // enable CDN
    CDN_GLOBAL_PREFIX = Main.config['siteAssetsBaseUrl'];

    let vars = {
        container: container,
        enableGui: false,
        enableStats: false,
    };

    if (Utils.canUseWebgl && !Main.mobile) {
        THREE.Cache.enabled = true;
        GridImages.preload(GridImages.PRELOAD_BATCH_SIZE);

        stage = new SeancesStage(api, vars, skipIntro, graveyard);
    } else {
        stage = new MobileStage(api, vars, skipIntro, graveyard);
    }

    header = new Header($('.top-bar'), {}, null);
    footer = new Footer($('#footer'), {}, null);
    credit = new CreditsScene($('.credits'), {}, null);
    about = new AboutScene($('.about'), {}, null);

    if (skipIntro || graveyard) {
        EventBus.dispatchEvent('INTRO_OVER');
    }
}

function buzzSoundCdn(url:string, volume:number = 80):buzz.sound {
    return new buzz.sound(CDN_GLOBAL_PREFIX + url, {
        'volume':volume
    });
}


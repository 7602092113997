/// <reference path='../../scaffolding/Component.ts'/>

class InstallationGraveyardTitle extends Component {
    private titleData: any;
    private jitterStrength:number = 500;

    constructor(container:JQuery, data:any, delegate:any) {
        super(container, data, delegate);
        this.setContent(Main.templates.find('.installationGraveyardTitle').clone());
        this.titleData = data;
        this.setText()
    }

    private setText(){
        this.content.html(this.data.title);

        this.content.css('top', this.data.top);

        let x = _.random(-this.jitterStrength, this.jitterStrength) / 100;
        let y = _.random(-this.jitterStrength, this.jitterStrength) / 100;
        let z = _.random(-this.jitterStrength, this.jitterStrength) / 100;
        let xform = 'translate3d(' + x + 'px,' + y + 'px,' + z + 'px)';

        this.content[0].style[Main.xform] = xform;
    }
}

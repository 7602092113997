/// <reference path='./Rect.ts'/>
/// <reference path='./TextureAtlas.ts'/>

interface Tex {
    map:THREE.Texture;
    textAspectRatio:number;
    uvs?:THREE.Vector2[]
}

interface GlyphHeight {
    height:number;
    descender:number;
}

class LineTextureRenderer {
    private face:string;
    private size:number;
    private lineHeight:GlyphHeight;

    private renderer:JQuery;
    private atlas:TextureAtlas;

    private padding:number = 6;

    constructor(face:string, size:number, atlas:TextureAtlas) {
        this.face = face;
        this.size = size;
        this.atlas = atlas;

        this.atlas.setFont(face, size);

        this.renderer = $("#renderer");
        this.renderer.css({
            width: 'auto',
            height: 'auto',
            'font-size': `${size}px`,
            'font-face': face,
        });

        this.lineHeight = this.measureHeight('A');
        this.atlas.changeRowHeight(this.lineHeight.height + this.lineHeight.descender, this.padding);
    }

    public makeLineTexture(text:string, extra?:string[]):Tex {
        this.atlas.setFont(this.face, this.size, extra);
        const dims = this.measureString(text);
        const empty = this.atlas.paintText(text, dims.w, dims.h, this.lineHeight, extra);

        const texUV = this.atlas.getTextureUV(empty);

        return {
            map: this.atlas.getTexture(),
            textAspectRatio: dims.exteriorWidth / dims.exteriorHeight,
            uvs: texUV
        }
    }

    private measureString(text:string):Rect {
        return new Rect(
            0,
            0,
            this.atlas.measureText(text).width,
            this.lineHeight.height + this.lineHeight.descender,
            this.padding
        );
    }

    private measureHeight(text:string):GlyphHeight {
        const glyphs = 'ijMqm';

        const calcArea = $(`<div>${glyphs}</div>`)
            .css('position', 'fixed')
            .css('top', '-1000000px')
            .css('left', '-1000000px')
            .css('font-size', '1000000%')
            .css('font-family', this.face)
            .css('line-height', '1.0');

        this.renderer.append(calcArea);

        const subSpan = $(`<span>${glyphs}</span>`)
            .css('font-size', '0%');

        calcArea.append(subSpan);

        const baselinePosition = subSpan.get(0).offsetTop + subSpan.get(0).offsetHeight;
        const calcAreaOffsetHeight = calcArea.get(0).offsetHeight;
        const baselineRatio = (calcAreaOffsetHeight - baselinePosition) / calcAreaOffsetHeight;

        const renderSpan = $(`<span>${text}</span>`)
            .css('font-size', `${this.size}px`)
            .css('font-face', this.face)
            .css('white-space', 'pre');

        this.renderer.append(renderSpan);

        const height = renderSpan.height();

        renderSpan.detach();
        calcArea.detach();

        return {
            height: height,
            descender: Math.ceil(height * baselineRatio)
        };
    }
}

class ProgressCircleShader implements THREE.ShaderMaterialParameters {

    constructor(radius:number = 0.6, thickness:number, filled?:boolean, colour?:THREE.Vector4) {
        this.defines.RADIUS = radius;
        this.uniforms.fProgress.value = filled ? 1.0 : 0.0;
        this.defines.THICKNESS = thickness;

        if (colour) {
            this.uniforms.vColour.value = colour;
        }
    }

    public uniforms = {
        fProgress: {type: "f", value: 0.5},
        vColour: {type: "v4", value: new THREE.Vector4(1.0, 1.0, 1.0, 1.0)}
    };

    public defines = {
        PI: Math.PI,
        RADIUS: 0.6,
        THICKNESS: null
    };

    public vertexShader = `
uniform float fProgress;

void main() {
    float rotation = (fProgress * (position.x + 0.5) * PI * float(2));
    
    vec2 screenSpace = mat2(
        cos(rotation), -sin(rotation),
        sin(rotation), cos(rotation)
    ) * vec2(0, position.y+float(RADIUS)+float(THICKNESS));
    
    gl_Position = projectionMatrix * modelViewMatrix * 
        vec4(
            screenSpace.x,
            screenSpace.y,
            position.z, 
            1.0
        );
} 
`;
    public fragmentShader = `
uniform vec4 vColour;

void main() {
    gl_FragColor = vColour;
}
`;
}

class RenderPass implements EffectPass {
    public enabled:boolean = true;
    public needsSwap:boolean = false;

    public scene:THREE.Scene;
    public camera:THREE.Camera;

    private clear:boolean = true;
    private overrideMaterial:THREE.Material;
    private clearColor:THREE.Color;
    private clearAlpha:number;
    private oldClearColor:THREE.Color = new THREE.Color();
    private oldClearAlpha:number = 1;


    constructor(
        scene:THREE.Scene,
        camera:THREE.Camera,
        overrideMaterial:THREE.Material,
        clearColor:THREE.Color,
        clearAlpha:number
    ) {

        this.scene = scene;
        this.camera = camera;

        this.overrideMaterial = overrideMaterial;

        this.clearColor = clearColor;
        this.clearAlpha = ( clearAlpha !== undefined ) ? clearAlpha : 1;

        this.oldClearColor = new THREE.Color();
        this.oldClearAlpha = 1;

        this.enabled = true;
        this.clear = true;
        this.needsSwap = false;
    }

    public render(renderer, writeBuffer, readBuffer, delta) {
        this.scene.overrideMaterial = this.overrideMaterial;

        if (this.clearColor) {

            this.oldClearColor.copy(renderer.getClearColor());
            this.oldClearAlpha = renderer.getClearAlpha();

            renderer.setClearColor(this.clearColor, this.clearAlpha);

        }

        renderer.render(this.scene, this.camera, readBuffer, this.clear);

        if (this.clearColor) {

            renderer.setClearColor(this.oldClearColor, this.oldClearAlpha);

        }

        this.scene.overrideMaterial = null;
    }
}

class ImageBackground extends THREE.Mesh {

    protected vEle:HTMLImageElement;
    private imagePool: string[];
    private tickFn:() => void;
    private vCtx:CanvasRenderingContext2D;
    private vTex:THREE.Texture;
    private realParent:THREE.Object3D;

    constructor(cameraHeight:number, imagePool:string[]) {
        // const imageElement
        const imageElment = document.createElement('img');
        imageElment.crossOrigin = 'anonymous';
        const image = document.createElement('canvas');
        image.width = 1280;
        image.height = 720;

        const imageContext = image.getContext('2d');
        imageContext.fillStyle = '#000000';
        imageContext.fillRect(0, 0, image.width, image.height);

        const imageTexture = new THREE.Texture(
            image,
            THREE.Texture.DEFAULT_MAPPING,
            THREE.ClampToEdgeWrapping,
            THREE.ClampToEdgeWrapping,
            THREE.LinearFilter,
            THREE.LinearFilter
        );
        imageTexture.minFilter = THREE.LinearFilter;
        imageTexture.magFilter = THREE.LinearFilter;

        const movieMaterial = new THREE.MeshBasicMaterial({map: imageTexture});

        const backgroundHeight = cameraHeight * 1.1;
        const backgroundRatio = image.width / image.height;
        const backgroundWidth = backgroundHeight * backgroundRatio;
        const geometry = new THREE.PlaneGeometry(backgroundWidth, backgroundHeight);

        // TODO: File bug with JetBrains about this
        //noinspection TypeScriptValidateTypes
        super(geometry, movieMaterial);
        this.vEle = imageElment;
        this.vCtx = imageContext;
        this.vTex = imageTexture;
        this.imagePool = imagePool;
        imageElment.src = this.getRandomVideoUrl();
        this.tickFn = () => {
            this.vCtx.drawImage(this.vEle, 0, 0);
            if (this.vTex) {
                this.vTex.needsUpdate = true;
            }
        };
        this.addEventListener('added', () => {
            this.realParent = this.parent;
            this.realParent.addEventListener(NickelScene.TICK_EVENT, this.tickFn);
        });
    }

    protected getRandomVideoUrl():string {
        let image = CDN_GLOBAL_PREFIX + '/img/bg_img/' + _.sample(this.imagePool)
        return image;
    }
}

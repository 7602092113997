/// <reference path='Main.ts'/>

/**
 * Helper functions available to all other Classes.
 */
class Utils {

    static easeQuadOut(e0, e1, t) {
        return e0 + (e1 - e0) * (t * ( 2 - t ));
    }

    static wrapText(context, text, x, y, maxWidth, lineHeight) {

        y = lineHeight;
        var words = text.split(' ');
        var line = '';

        for (var n = 0; n < words.length; n++) {
            var testLine = line + words[n] + ' ';
            var metrics = context.measureText(testLine);
            var testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, y);
                line = words[n] + ' ';
                y += lineHeight;
            } else {
                line = testLine;
            }
        }
        context.fillText(line, x, y);
    }

    static pow2floor(v:number):number {
        v++;
        var p = 1;
        while (v >>= 1) {
            p <<= 1;
        }
        return p;
    }

    static pow2ceil(v:number):number {
        v--;
        var p = 2;
        while (v >>= 1) {
            p <<= 1;
        }
        return p;
    }

    static calcCameraView(objZ):CameraView {

        var distance = Stage.CAMERA.position.z + -objZ;
        var vFOV, height, hFOV, width;
        vFOV = (Stage.CAMERA.fov * Math.PI / 180);
        height = 2 * Math.tan(((Stage.CAMERA.fov * Math.PI / 180) / 2 )) * distance;
        hFOV = 2 * Math.atan(Math.tan(vFOV / 2) * Stage.CAMERA.aspect);
        width = 2 * Math.tan(( hFOV / 2 )) * distance;
        return {width: width, height: height}
    }

    //calculates the pixel distance between two 3d points
    static distanceBetween3DPoints(p1:THREE.Vector3, p2:THREE.Vector3):number {

        return Math.round(Math.sqrt(((p2.x - p1.x) * (p2.x - p1.x)) + ((p2.y - p1.y) * (p2.y - p1.y)) + ((p2.z - p1.z) * (p2.z - p1.z))));
    }

    public static angleBetween3DPoints(p1:THREE.Vector3, p2:THREE.Vector3):number {
        var xDiff = p2.x - p1.x;
        var yDiff = p2.y - p1.y;

        return Math.atan2(yDiff, xDiff) * (180 / Math.PI);
    }

    /* --------------------------------------------------------*/
    /* --------------------- MISC HELPERS ---------------------*/
    /* --------------------------------------------------------*/

    /**
     * Resizes a rectangle to fit into another rectangle using different positioning and scale properties to crop / position.
     * @data    The data object containig all of the paraeters for the calculation.
     */
    static fitToContainer(data:ResizeValues):FitValues {

        var newH, newW, top, left;
        var aspectRatio = data.contentWidth / data.contentHeight;

        //scale
        if (data.scaleMode == "proportionalInside") {

            newW = data.containerWidth;
            newH = newW / aspectRatio;

            if (newH > data.containerHeight) {
                newH = data.containerHeight;
                newW = newH * aspectRatio;
            }

        } else if (data.scaleMode == "proportionalOutside") {

            newW = data.containerWidth;
            newH = newW / aspectRatio;

            if (newH < data.containerHeight) {
                newH = data.containerHeight;
                newW = newH * aspectRatio;
            }

        } else if (data.scaleMode == "none" || !data.scaleMode) {

            newW = data.contentWidth;
            newH = data.contentHeight;
        }

        if (data.maxWidth) {
            if (newW > data.maxWidth) {
                newW = data.maxWidth;
                newH = newW / aspectRatio;
            }
        }

        if (data.maxHeight) {
            if (newH > data.maxHeight) {
                newH = data.maxHeight;
                newW = newH * aspectRatio;
            }
        }

        //fit
        left = (data.hAlign == "left") ? 0 : (data.hAlign == "right") ? -(newW - data.containerWidth) : (data.containerWidth - newW) / 2;
        top = (data.vAlign == "top") ? 0 : (data.vAlign == "bottom") ? -(newH - data.containerHeight) : (data.containerHeight - newH) / 2;

        return {
            'width': newW,
            'height': newH,
            'top': top,
            'left': left
        };
    }

    /**
     * Generates a UUID and returns it.
     */
    static generateUUID():string {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
        return uuid;
    }

    /**
     * Generates a unique id, based off of the langth passed in.
     * @numChars    How may characters we want the (psuedo) unique ID to contain.
     */
    static generateID(numChars:number):string {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < numChars; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    /**
     * Wrapper function to make an ajax request.
     * @data                The JSON data we want to pas in.
     * @successCallback     Callback function for on success.
     * @errorCallback       Callback function for error.
     */
    static ajax(data:any, successCallback = null, errorCallback = null, ajaxUrl = null) {
        $.ajax({
            type: 'POST',
            url: 'ajax.cms.php',
            data: data,
            dataType: 'json',
            success: function (response) {
                if (successCallback) {
                    successCallback(response);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                if (errorCallback) {
                    errorCallback(jqXHR.responseText);
                }
            }
        });
    }

    /**
     * Pushes a browser state
     * @state   The state we want to push.
     */
    static pushState(state) {
        // History['pushState']({state:1}, Main.config.pageTitle, state);
        History['pushState']({state: 1}, Main.config.title, state);
    }


    /**
     *Opens a popup window centered in the screen
     */
    static openWindow(url:string, width:number, height:number) {
        var windowSize = {
            'width': width,
            'height': height,
            'left': (screen.width / 2) - (width / 2),
            'top': (screen.height / 2) - (height / 2 + 100)
        }
        var windowFeatures = "width=" + windowSize.width + ",height=" + windowSize.height + ",status,resizable,scrollbars,modal,alwaysRaised";
        windowFeatures += ",left=" + windowSize.left + ",top=" + windowSize.top + "screenX=" + windowSize.left + ",screenY=" + windowSize.top;
        window.open(url, '' + new Date().getTime() + '', windowFeatures);
    }


    /* --------------------------------------------------------*/
    /* ------------------------ STRING ------------------------*/
    /* --------------------------------------------------------*/

    /**
     * Capitalises the first letter of a string.
     * @str     The string you want to capitalise.
     */
    static capitalize(str:string):string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    /**
     * Returns a formatted string for displaying the file size, from bytes.
     * @bytes       The filesize, in bytes.
     */
    static formatBytes(bytes:any):string {
        if (bytes >= 1000000000) {
            bytes = (bytes / 1000000000).toFixed(2) + ' GB';
        }
        else if (bytes >= 1000000) {
            bytes = (bytes / 1000000).toFixed(2) + ' MB';
        }
        else if (bytes >= 1000) {
            bytes = (bytes / 1000).toFixed(2) + ' KB';
        }
        else if (bytes > 1) {
            bytes = bytes + ' bytes';
        }
        else if (bytes == 1) {
            bytes = bytes + ' byte';
        }
        else {
            bytes = '0 byte';
        }
        return bytes;
    }

    /* --------------------------------------------------------*/
    /* ------------------------ OBJECT ------------------------*/
    /* --------------------------------------------------------*/

    /**
     * Counts the key/value pairs in an object.
     * @obj     The object you want to get a "length" from.
     */
    static objSize(obj:any):number {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    }

    /* --------------------------------------------------------*/
    /* ----------------------- NUMBERS ------------------------*/
    /* --------------------------------------------------------*/

    /**
     * Detects if a value is numberic or not.
     * @n   The value we want to check.
     */
    static isNumeric(n:any):boolean {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    /**
     * Detects if a number is odd or event
     * @num     The value we want to check.
     */
    static isOdd(num:number):boolean {
        return (num % 2) == 16
    }

    /**
     * Contain a number to a min and max.
     */
    static clamp(min:number, max:number, val:number):number {
        if (val < min) return min;
        if (val > max) return max;
        return val;
    }

    /* --------------------------------------------------------*/
    /* ------------------------- MATH -------------------------*/
    /* --------------------------------------------------------*/

    /**
     * Convert degrees to radians.
     */
    static degreesToRadians(degrees:number):number {

        return degrees * Math.PI / 180;
    }

    /**
     * Convert radians to degrees
     */
    static radiansToDegrees(radians:number):number {

        return radians * 180 / Math.PI;
    }

    /**
     * Calculates the distance between two points in 2D space.
     */
    static lineDistance(point1:Point, point2:Point):number {
        var xs = 0;
        var ys = 0;
        xs = point2.x - point1.x;
        xs = xs * xs;
        ys = point2.y - point1.y;
        ys = ys * ys;
        return Math.sqrt(xs + ys);
    }

    /**
     * Calculates the angle in degrees between two points
     */
    static calcAngle(p1:Point, p2:Point):number {

        var calcAngle = Math.atan2(p1.x - p2.x, p1.y - p2.y) * (180 / Math.PI);
        if (calcAngle < 0)
            calcAngle = Math.abs(calcAngle);
        else
            calcAngle = 360 - calcAngle;
        return calcAngle;
    }

    /**
     * Returns a random number between 2 numbers
     */
    static randomFromInterval(from:number, to:number) {
        return Math.floor(Math.random() * (to - from + 1) + from);
    }

    /* --------------------------------------------------------*/
    /* ------------------------- ARRAY ------------------------*/
    /* --------------------------------------------------------*/

    /**
     * Switches the position of two array elements.
     * @array       The array containing both elements.
     * @a           The index of the first element.
     * @b           The index of the second element.
     * @return      The array with the elements switched.
     */
    static swapArrayElements(array:any[], a:any, b:any):any[] {
        var temp = array[a];
        array[a] = array[b];
        array[b] = temp;
        return array;
    }

    /**
     * Removes one of more elements from an array.
     * @array       The array containing all of the elements.
     * @from        The first element we want to remove (and the last, if @to isn't set).
     * @to          The index of the last element we want to remove.
     * @return      The original array with the elements removed.
     */
    static removeFromArray(array:any[], from:number, to?:number):any[] {
        var rest = array.slice((to || from) + 1 || array.length);
        array.length = from < 0 ? array.length + from : from;
        return array.push.apply(array, rest);
    }

    /**
     * Randomly shuffles the contents of an array.
     * @array       The array containing all of the elements.
     */
    static shuffleArray(array:any[]):any[] {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }

    /* --------------------------------------------------------*/
    /* -------------------------- JSON ------------------------*/
    /* --------------------------------------------------------*/

    /**
     * Checks if a string is valid json.
     * @str     The string you want to check.
     */
    static isValidJSON(str:string):boolean {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    /**
     * Formats a JSON sting with line breaks for displaying pretty JSON.
     * @str     The string you want to format.
     */
    static formatJSONString(str:string):string {

        var jsonObj = JSON.parse(str);
        var jsonPretty = JSON.stringify(jsonObj, null, '\t');
        return jsonPretty;
    }

    /* --------------------------------------------------------*/
    /* ----------------- BROWSER / OS DETECTION ---------------*/
    /* --------------------------------------------------------*/

    /**
     * Detects the operating system on a mobile device, returns and os and the version.
     */
    static detectMobileOS():MobileOS {

        var mobileOS;
        var mobileOSver;
        var ua = navigator.userAgent;
        var uaindex;

        // determine OS
        if (ua.match(/iPad/i) || ua.match(/iPhone/i)) {
            mobileOS = 'iOS';
            uaindex = ua.indexOf('OS ');
        } else if (ua.match(/Android/i)) {
            mobileOS = 'Android';
            uaindex = ua.indexOf('Android ');
        } else {
            mobileOS = 'unknown';
        }

        // determine version
        if (mobileOS === 'iOS' && uaindex > -1) {
            mobileOSver = ua.substr(uaindex + 3, 3).replace('_', '.');
        } else if (mobileOS === 'Android' && uaindex > -1) {
            mobileOSver = ua.substr(uaindex + 8, 3);
        } else {
            mobileOSver = 'unknown';
        }

        var num = Number(mobileOSver);
        return {"os": mobileOS, "ver": num};
    }

    /* --------------------------------------------------------*/
    /* -------------------- FEATURE DETECTION -----------------*/
    /* --------------------------------------------------------*/

    /**
     * Detects if the current browser can play the mp4 video format or now.
     */
    static canPlayMP4():boolean {

        var canPlay = false;
        var v = document.createElement('video');
        if (v.canPlayType && v.canPlayType('video/mp4').replace(/no/, '')) {
            canPlay = true;
        }
        return canPlay;
    }

    /**
     * Detects if the current browser supports Webgl.
     */

    static canUseWebgl():boolean{
        if (!!window.WebGLRenderingContext) {
            return true;
        } else {
            return false;
        }
    }

    /* --------------------------------------------------------*/
    /* ----------------------- DATE / TIME --------------------*/
    /* --------------------------------------------------------*/

    static clientTimezone(date) {
        return date.addHours(-(new Date().getTimezoneOffset() / 60));
    }

    static formatDate(date, format) {
        var d = Utils.clientTimezone(date);
        return date.toString(format).replace(' 0:', ' 12:'); // Fix date.js bug to show 12:00am instead of 0:00am
    }

    /* --------------------------------------------------------*/
    /* ------------------------ COOKIES -----------------------*/
    /* --------------------------------------------------------*/

    /**
     *Sets a cookie to the document object
     * @c_name      The name for the new cookie.
     * @value       The data to set on this cookie.
     * @exdays      How many days befor this cookie expires.
     */
    static setCookie(c_name:string, value:any, exdays:number) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value = value + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString()) + ";path=/";
        document.cookie = c_name + "=" + c_value;
    }

    /**
     * Retrives a cookie from the document object using the cookies name
     * @c_name      Label of the cookie you want to retrieve.
     */
    static getCookie(c_name:string):any {
        var c_value = document.cookie;
        var c_start = c_value.indexOf(" " + c_name + "=");
        if (c_start == -1) {
            c_start = c_value.indexOf(c_name + "=");
        }
        if (c_start == -1) {
            c_value = null;
        }
        else {
            c_start = c_value.indexOf("=", c_start) + 1;
            var c_end = c_value.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = c_value.length;
            }
            c_value = c_value.substring(c_start, c_end);
        }
        return c_value;
    }

    /* --------------------------------------------------------*/
    /* ---------------------- VALIDATION ----------------------*/
    /* --------------------------------------------------------*/

    /**
     * Validates an email address.
     */
    static realEmail(addressToTest):boolean {
        var regPattern = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
        return regPattern.test(addressToTest);
    }
}

interface ResizeValues {
    containerWidth:number;
    containerHeight:number;
    contentWidth:number;
    contentHeight:number;
    scaleMode:string;
    hAlign:string;
    vAlign:string;
    maxWidth?:number;
    maxHeight?:number;
}

interface FitValues {
    'top':number;
    'left':number;
    'width':number;
    'height':number;
}

interface MobileOS {
    "os":string;
    "ver":number;
}

interface Point {
    "x":number;
    "y":number;
}


class StoryUtils {

    static getFirstCutTime(story:any, sceneId:string):number {

        var time = 0;
        var scene = StoryUtils.getScene(story, sceneId);
        if (scene) {
            var cuts = scene.sceneData.cuts;
            var first = cuts[0];
            if (first) {
                time = first.startFrame / scene.sceneData.videoFile.rate;
            }
        }
        return time;
    }

    static getScene(story:any, sceneId:string, params?:string[]):any {

        var r = null;
        if (story.acts) {

            for (var key in story.acts) {

                if (story.acts.hasOwnProperty(key)) {

                    var act = story.acts[key];

                    if (act.scenes) {

                        for (var s in act.scenes) {

                            if (act.scenes.hasOwnProperty(s)) {

                                var scene = act.scenes[s];
                                if (scene.id == sceneId) {
                                    r = scene;
                                    return r;
                                }
                            }
                        }
                    }
                }
            }
        }
        return r;
    }

    static getAct(story:any, actId:string, params?:string[]):any {

        var act = null;
        if (story.acts) {

            if (story.acts.hasOwnProperty(actId)) {

                act = story.acts[actId];
                return act;
            }
        }
        return act;
    }

    static getInventory(story:any, invId:string):any {

        var r = null;

        //run through all of the acts, and their scenes, and look for the label matching this ID
        if (story.acts) {

            for (var key in story.acts) {

                if (story.acts.hasOwnProperty(key)) {

                    var act = story.acts[key];

                    if (act.inventory) {

                        for (var s in act.inventory) {

                            if (act.inventory.hasOwnProperty(s)) {

                                var inv = act.inventory[s];
                                if (inv.id == invId) {
                                    r = inv;
                                    return r;
                                }
                            }
                        }
                    }
                }
            }
        }

        return r;
    }
}

Math.radians = function (degrees) {
    return degrees * Math.PI / 180;
};

// Converts from radians to degrees.
Math.degrees = function (radians) {
    return radians * 180 / Math.PI;
};

interface CameraView {
    width:number;
    height:number;
}

interface NumberConstructor {
    clamp(min:number, max:number);
}

Number.clamp = function (min:number, max:number):number {
    return Math.min(Math.max(this, min), max);
};

interface NColor extends THREE.Color {
    toVector4(alpha?:number):THREE.Vector4
}

THREE.Color.prototype['toVector4'] = function (alpha?:number):THREE.Vector4 {
    if (!alpha) {
        alpha = 1.0;
    } else {
        if (alpha < 0.0 || alpha > 1.0) {
            console.warn('First parameter passed to THREE.Color.toVector4 must be 0.0 <= alpha <= 1.0');
            alpha = Number.clamp(0.0, 1.0);
        }
    }
    return new THREE.Vector4(this.r, this.g, this.b, alpha);
};


/// <reference path='../../scaffolding/Component.ts'/>
/// <reference path='../../scaffolding/EventBus.ts'/>
class AboutScene extends Component {

    constructor(container, data, delegate) {
        super(container, data, delegate);

        this.content = container;
        this.resize();
        $(".closeBtn").bind('click', ()=>this.hideMe());
        EventBus.addEventListener('showabout', ()=>this.showMe(), this);
        EventBus.addEventListener('hideabout', ()=>this.hideMe(), this);
    }
}
class NickelGroup extends THREE.Group {
    constructor() {
        super();
        this.addEventListener(
            NickelScene.TICK_EVENT,
            e => this.children.forEach(c => c.dispatchEvent({
                type: NickelScene.TICK_EVENT,
                message: {dt: e.message.dt}
            }))
        );
    }
}

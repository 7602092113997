/// <reference path='../scaffolding/Component.ts'/>
/// <reference path='../scaffolding/Share.ts'/>

class Header extends Component {
    private menuIsOpen:boolean = false;
    
    constructor(container:JQuery, data:any, delegate:any) {

        super(container, data, delegate);

        this.content = container;

        $("#twitter").bind('click', (e)=>this.shareTwitter(e));
        $("#fb").bind('click', (e)=>this.shareFacebook(e));
        $('#nav-toggle').bind('click touchend', ()=>this.menuOpen());

        EventBus.addEventListener('INTRO_OVER', ()=>this.showMe(), this);
        EventBus.addEventListener('SHOW_HEADER', ()=>this.showMe(), this);
        EventBus.addEventListener('HIDE_HEADER', ()=>this.hideMe(), this);
    }

    private menuOpen() {
        $('#mobilenav').toggleClass('menu-open');
        $('body').toggleClass('menu-open');
    }

    private shareTwitter(e) {

        e.preventDefault();

        let copy = 'Seances – an indefatigable machine that makes never before seen films, never to be seen again!';

        Share.onTwitter(Main.config.shareUrl, copy);

        gaTrackEvent('share_twitter', 'click');
    }

    private shareFacebook(e) {

        e.preventDefault();

        Share.onFacebook(Main.config.shareUrl);

        gaTrackEvent('share_facebook', 'click');
    }
}

/// <reference path='../../scaffolding/three/NickelGroup.ts'/>
/// <reference path='./InstallationProgressCircle.ts'/>
/// <reference path='../scenes/MultiTouchInterfaceScene.ts'/>

class PhotoCard extends NickelGroup {

    public static ADD_TO_TITLE:string = "addtotitle";
    public static REMOVE_CARD:string = "removecard";
    public static COUNTDOWN_COMPLETE:string = "countdowncomplete";

    public cardWidth:number = 128;
    public cardHeight:number = 72;
    public initialScale:number = 0.1;
    private minLife:number = 3;
    private maxLife:number = 15;
    private jitterStart:number = 1;
    private jitterEnd:number = 5;
    private countdownDuration:number = 3;
    public useRate:number = 1;
    private jitterStrength:number = 0;
    private minDistance = 0;

    private circle:InstallationProgressCircle;
    public cardPos:CardPos;
    
    public targetScale:number;
    public targetRotation:number;
    private initialOpacity:number;
    private offsetX:number;
    private offsetY:number;
    public touchIndex:number;
    public imgSrc:string;

    private lastDragTime:any;
    private dragTime:any;
    private distance:number;
    private speedX:number;
    private speedY:number;

    public interactive:boolean = false;
    public countdownRunning:boolean = false;
    private jitter:boolean = true;
    
    private tween:TweenMax;
    private animTween:TweenMax;
    private jitterTween:TweenMax;
    private momentumTween:TweenMax;

    public textureLoader:THREE.TextureLoader;
    public imageMesh:THREE.Mesh;
    public swapImageMesh:THREE.Mesh;
    private currentPosition:THREE.Vector3;
    private lastPosition:THREE.Vector3;

    private swapFunc:()=> void;
    private removeFunc:() => void;

    private touchSound:any;
    private cancelSound:any;
    private successSound:any;
    
    constructor(textureLoader:THREE.TextureLoader, img:string) {

        super();

        this.textureLoader = textureLoader;
        this.imgSrc = img;

        this.imageMesh = this.getMesh();
        this.swapImageMesh = this.getMesh();

        this.imageMesh.scale.set(this.initialScale, this.initialScale, this.initialScale);
        this.add(this.imageMesh);

        this.addEventListener(NickelScene.TICK_EVENT, ()=>this.tick());
        this.addEventListener("added", () => {
            if (this.parent instanceof CardLayer) {
                this.imageMesh.renderOrder = RenderOrder.BackgroundPhotoCards;
            } else if (this.parent instanceof MultiTouchInterfaceScene) {
                this.imageMesh.renderOrder = RenderOrder.CurrentPhotoCard;
            }
        });

        this.touchSound = buzzSoundCdn('/audio/del_01_click.mp3');
        this.cancelSound = buzzSoundCdn('/audio/click_fail.mp3');
        this.successSound = buzzSoundCdn('/audio/click_success.mp3');
    }

    public setPosition(pos:CardPos) {

        if(this.animTween){
            this.animTween.kill();
        }

        if(this.momentumTween){
            this.momentumTween.kill();
        }

        this.cardPos = pos;
        this.position.x = this.cardPos.position.x;
        this.position.y = this.cardPos.position.y;
        this.position.z = this.cardPos.position.z;
        this.rotation.z = this.cardPos.rotation;
    }

    public tick() {

        if (this.countdownRunning) {


            this.circle.updateProgress(this.circle.tweenVar);

            if (this.circle.tweenVar >= 1) {
                this.countdownOver();
            }
        }
    }

    public getMesh():any {
        const tex = this.textureLoader.load(this.imgSrc);
        const mat = new THREE.MeshBasicMaterial({'map': tex, 'transparent': true});
        const geo = new THREE.PlaneGeometry(
            this.cardWidth,
            this.cardHeight
        );
        return new THREE.Mesh(geo, mat);
    }

    public swapImage() {

        this.add(this.swapImageMesh);
        this.remove(this.imageMesh);
        let old = this.imageMesh;
        this.imageMesh = this.swapImageMesh;
        this.swapImageMesh = old;
    }

    public show() {

        this.targetScale = _.random(15, 20) / 100;
        this.initialOpacity = _.random(50, 80) / 100;
        this.targetScale = _.random(15, 20) / 100;
        this.targetRotation = Math.radians(_.random(-5, 5));

        let dly = _.random(500, 1000) / 1000;
        let opac = _.random(30, 40) / 100;
        let translate = _.random(30, 80) / 100;

        //position
        TweenMax.fromTo(this.imageMesh.position, translate, 
        {
            x:_.random(-60, 60),
            y:_.random(-60, 60)
        },
        {
            x:0,
            y:0,
            ease:Strong.easeOut,
            delay: dly
        });

        //rotation
        TweenMax.fromTo(this.imageMesh.rotation, translate, 
        {
            x:Math.radians(_.random(-120, 120)),
            y:Math.radians(_.random(-120, 120)),
            z:Math.radians(_.random(-50, 50)),
        },
        {
            x:0,
            y:0,
            z:this.targetRotation,
            ease:Strong.easeOut,
            delay: dly
        });
        
        //scale
        TweenMax.to(this.imageMesh.scale, translate, {
                x: this.targetScale,
                y: this.targetScale,
                z: this.targetScale,
                delay: dly,
                ease:Quad.easeOut,
                onComplete: ()=> {
                    this.imageMesh.geometry.computeBoundingBox();
                    this.interactive = true;
                }
            }
        );

        //opacity
        TweenMax.fromTo(this.imageMesh.material, opac, 
        {
            opacity:0
        },
        {
            opacity: this.initialOpacity,
            delay: dly,
        });

        const cycleDly = _.random(this.minLife, this.maxLife);

        if (MultiTouchInterfaceScene.AUTO_CYCLE) {
            let center = (_.random(1, 100) <= 1);
            this.removeFunc = () => this.hide(center);
            TweenMax.delayedCall(cycleDly, this.removeFunc);
        }

        if (MultiTouchInterfaceScene.SWAP_IMAGES && _.random(0, 100) <= 20 && this.swapImageMesh) {
            this.swapFunc = ()=> this.swapImage();
            TweenMax.delayedCall(cycleDly * _.random(40, 60) / 100, this.swapFunc);
            this.swapImageMesh.scale.set(this.targetScale, this.targetScale, this.targetScale);
            this.swapImageMesh.rotation.set(0,0, this.targetRotation);
            this.swapImageMesh.geometry.computeBoundingBox();  
        }
    }

    public hide(center:boolean = false) {

        let dly = 0.4;

        this.interactive = false;
        this.countdownRunning = false;


        TweenMax.killDelayedCallsTo(this.removeFunc);
        TweenMax.killDelayedCallsTo(this.swapFunc);

        if (center) {

            this.removeFromCircle();

            if(this.animTween){
                this.animTween.kill();
            }

            if(this.momentumTween){
                this.momentumTween.kill();
            }

            //animate to the middle
            this.animTween = TweenMax.to(this.position, 0.7, {
                x: 0,
                y: 40,
                z: -150,
            });

            this.tweenOpacity(0.3, 0, Linear.easeNone, 0.15);

            TweenMax.delayedCall(dly, () => EventBus.dispatchEvent(PhotoCard.ADD_TO_TITLE));

        } else {
            this.tweenOpacity(1, 0, Quad.easeIn);

            this.animTween = TweenMax.to(this.imageMesh.scale, 1.5, {
                x: this.initialScale,
                y: this.initialScale,
                z: this.initialScale,
                ease: Quad.easeIn
            });

            
            if (this.distance > this.minDistance) {

                let duration = Math.max(Math.abs(this.speedX), Math.abs(this.speedY)) * 4000 / 1000;

                this.momentumTween = TweenMax.to(this.imageMesh.position, duration, {
                    onUpdate:(evt)=>{
                        let progress = this.momentumTween.progress();

                        this.speedX *= (1 - progress);
                        this.speedY *= (1 - progress);

                        var now = new Date();
                        var stepDuration = now.getTime() - this.lastStepTime.getTime();
                        this.lastStepTime = now;

                        var newX = (this.position.x + (this.speedX * stepDuration / 2)),
                            newY = (this.position.y + (this.speedY * stepDuration / 2));
                        
                        this.position.x = newX;
                        this.position.y = newY;

                        this.checkProximity();
                    },
                    onComplete:()=>this.removeFromCircle()
                })
            }
        }

        TweenMax.delayedCall(0.8, () => EventBus.dispatchEvent(PhotoCard.REMOVE_CARD, this));
    }

    private lastStepTime:any;


    public touchStarted(mouse:THREE.Vector3, id) {

        this.touchIndex = id;

        this.tweenOpacity(0.2, 1);
        this.removeFromCircle();

        mouse.unproject(Stage.CAMERA);
        const dir = mouse.sub(Stage.CAMERA.position).normalize();
        const dist = (this.cardPos.position.z - Stage.CAMERA.position.z) / dir.z;
        const var5 = Stage.CAMERA.position.clone().add(dir.multiplyScalar(dist));

        this.offsetX = var5.x - this.position.x;
        this.offsetY = var5.y - this.position.y;

        this.touchSound.play();

        this.startCountdown();
    }

    public removeFromCircle() {
        if (this.parent instanceof CardLayer) {
            this.parent.parent.add(this);
            this.position.setFromMatrixPosition(this.matrixWorld);
            this.rotation.setFromRotationMatrix(this.matrixWorld);
        }
    }

    private startCountdown() {

        this.countdownRunning = true;

        TweenMax.killDelayedCallsTo(this.removeFunc);
        TweenMax.killDelayedCallsTo(this.swapFunc);

        if (!this.circle) {
            this.circle = new InstallationProgressCircle();
            this.add(this.circle);
        }

        this.tween = TweenMax.fromTo(
            this.circle,
            this.countdownDuration / 16,
            {tweenVar: 0},
            {
                tweenVar: 0.5,
                onComplete: () => {
                    this.tween = TweenMax.to(
                        this.circle,
                        15 * this.countdownDuration / 16,
                        {
                            tweenVar: 1,
                            onComplete: () => this.countdownOver()
                        }
                    );
                }
            }
        )

        if(this.jitter){

            this.jitterTween = TweenMax.fromTo(
                this,
                this.countdownDuration,
                {
                    jitterStrength:this.jitterStart
                },
                {
                    jitterStrength:this.jitterEnd,
                    ease:Quint.easeIn,
                    onUpdate:()=>{

                        let x = Utils.randomFromInterval(-this.jitterStrength * 100, this.jitterStrength * 100) / 100;
                        let y = Utils.randomFromInterval(-this.jitterStrength * 100, this.jitterStrength * 100) / 100;
                        this.imageMesh.position.set(x, y, this.imageMesh.position.z);
                    }
                }
            );
        }
    }

    private countdownOver() {

        if(this.tween){
            this.tween.kill();
        }

        this.circle.reset();

        EventBus.dispatchEvent(PhotoCard.COUNTDOWN_COMPLETE, this)

        this.successSound.play();

        this.hide(true);
    }

    private endCountdown() {

        this.tween.kill();
        if(this.jitter){
            this.jitterTween.kill();
        }
        this.circle.reset();

        this.cancelSound.play();

        this.hide();
    }

    public touchMove(pos:THREE.Vector3, evt) {

        this.lastPosition = this.position.clone();
        this.lastDragTime = this.dragTime;

        pos.unproject(Stage.CAMERA);
        const dir = pos.sub(Stage.CAMERA.position).normalize();
        const dist = (this.cardPos.position.z - Stage.CAMERA.position.z) / dir.z;
        const var5 = Stage.CAMERA.position.clone().add(dir.multiplyScalar(dist));

        this.position.x = var5.x - this.offsetX;
        this.position.y = var5.y - this.offsetY;

        //momentum 
        this.currentPosition = this.position;
        this.dragTime = evt.timeStamp;

        let dX = this.currentPosition.x - this.lastPosition.x,   
            dY = this.currentPosition.y - this.lastPosition.y,
            dMs = Math.max(this.dragTime - this.lastDragTime, 1);

        // Speeds
        this.speedX = Math.max(Math.min(dX/dMs, 1), -1),
        this.speedY = Math.max(Math.min(dY/dMs, 1), -1);

        // Distance moved (Euclidean distance)
        this.distance = Math.sqrt(Math.pow(this.lastPosition.x-this.currentPosition.x, 2) + Math.pow(this.lastPosition.y-this.currentPosition.y, 2));

        this.lastStepTime = new Date();

        if(MultiTouchInterfaceScene.PROXIMITY_TRIGGER){
            this.checkProximity();
        }
    }

    private checkProximity(){

        let r = 50;
        let centerX = 0;
        let centerY = 50;
        let x:number = this.position.x;
        let y:number = this.position.y;

        if(Math.sqrt((x-centerX)*(x-centerX) + (y-centerY)*(y-centerY)) < r ){
            this.countdownOver();
        }
    }

    public touchEnd() {

        this.endCountdown();
    }

    public tweenOpacity(duration:number, opacity:number, easing?:Ease|Linear, delay?:number):Animation {
        this.imageMesh.material.depthWrite = (opacity === 1.0);

        let opts = {opacity: opacity};
        if (easing) {
            opts['easing'] = easing;
        }

        const tween = TweenMax.to(this.imageMesh.material, duration, opts);

        if (delay) {
            tween.delay(delay);
        }

        return tween;
    }
}

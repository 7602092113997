/// <reference path='../ProgressCircle.ts'/>

class InstallationProgressCircle extends ProgressCircle {

    constructor() {

        super(null);

        this.scale.set(6, 6, 6);

        // this.position.z = 0;
    }

    public setPos(pos:THREE.Vector2) {

        this.position.x = pos.x;
        this.position.y = pos.y;
    }

    public destroy() {

    }
}

/// <reference path='../scaffolding/Component.ts'/>

class Footer extends Component {

    static muted:boolean = false;
    private fullScreen:boolean = false;
    private aboutOpen:boolean = false;
    private creditsOpen:boolean = false;
    static SHOW_CREDITS:string = "showcredits";
    static SHOW_ABOUT:string = "showabout";

    static HIDE_CREDITS:string = "hidecredits";
    static HIDE_ABOUT:string = "hideabout";
    static MUTE_VIDEO: string = "mutevideo";

    private footerisHidden: boolean = false;

    constructor(container:JQuery, data:any, delegate:any) {
        super(container, data, delegate);

        this.content = container;

        $('#volumeCtrl').bind('mouseup touchend', (e) => this.toggleMute(e));
        $('#fullscreenCtrl').bind('mouseup touchend', () => this.toggleFullSceen());

        $('#creditScreen-mobile, #creditScreen').bind('click touchend', ()=>this.goToCredits());
        $('#aboutScreen-mobile, #aboutScreen').bind('click touchend', ()=>this.gotToAbout());
        $('#startOver-mobile, #startOver').bind('click touchend', ()=>this.startOver());


        EventBus.addEventListener('SHOW_HEADER', ()=>this.showMe(), this);
        EventBus.addEventListener('HIDE_HEADER', ()=>this.hideMe(), this);

        EventBus.addEventListener('INTRO_OVER', ()=>this.showMe(), this);

        document.addEventListener('webkitfullscreenchange', ()=>this.checkFullScreen());
        document.addEventListener('mozfullscreenchange', ()=>this.checkFullScreen());
        document.addEventListener('fullscreenchange', ()=>this.checkFullScreen());
        document.addEventListener('MSFullscreenChange', ()=>this.checkFullScreen());

        if(Main.mobile){
            $('#fullscreenCtrl').css('display', 'none');
            $('#volumeCtrl').addClass('footerBtn-last')
        }
    }

    private goToCredits() {
        if (this.aboutOpen) {
            this.aboutOpen = false;
            EventBus.dispatchEvent(Footer.HIDE_ABOUT);
        }
        this.creditsOpen = true;
        EventBus.dispatchEvent(Footer.SHOW_CREDITS);

        gaTrackEvent('footer_credits', 'click');
    }

    private gotToAbout() {
        if (this.creditsOpen) {
            this.creditsOpen = false;
            EventBus.dispatchEvent(Footer.HIDE_CREDITS);
        }
        this.aboutOpen = true;
        EventBus.dispatchEvent(Footer.SHOW_ABOUT);

        gaTrackEvent('footer_about', 'click');
    }

    private startOver() {

        gaTrackEvent('footer_start_over', 'click');
        history.pushState({ state: '/skipIntro' }, Main.config.title, '/skipIntro');
        // History['pushState']({state: 1}, Main.config.title, state);
        location.reload();
    }

    private toggleMute(e) {
        e.preventDefault();
        gaTrackEvent('footer_toggle_mute', 'click');
        EventBus.dispatchEvent(Footer.MUTE_VIDEO);
        if (Footer.muted) {
            Footer.muted = false;
            $('#volumeIcon').css('background-image', 'url(img/volume-icon-active.png)');
            for (var i in buzz.sounds) {
                buzz.sounds[i].unmute();
            }

        } else {
            for (var i in buzz.sounds) {
                buzz.sounds[i].mute();
            }
            $('#volumeIcon').css('background-image', 'url(img/mute.png)');
            Footer.muted = true;
        }

    }

    private checkFullScreen(){     
        let doc:any = document;   
        if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
            EventBus.dispatchEvent('SHOW_HEADER');
        } else {
            EventBus.dispatchEvent('HIDE_HEADER');
        }

    }
    private toggleFullSceen() {

        gaTrackEvent('footer_fullscreen', 'click');

       

        let doc:any = document;
        if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
            if (doc.documentElement.requestFullscreen) {
                doc.documentElement.requestFullscreen();
            } else if (doc.documentElement.msRequestFullscreen) {
                doc.documentElement.msRequestFullscreen();
            } else if (doc.documentElement.mozRequestFullScreen) {
                doc.documentElement.mozRequestFullScreen();
            } else if (doc.documentElement.webkitRequestFullscreen) {
                doc.documentElement.webkitRequestFullscreen();
            }
        } else {
            if (doc.exitFullscreen) {
                doc.exitFullscreen();
            } else if (doc.msExitFullscreen) {
                doc.msExitFullscreen();
            } else if (doc.mozCancelFullScreen) {
                doc.mozCancelFullScreen();
            } else if (doc.webkitExitFullscreen) {
                doc.webkitExitFullscreen();
            }
        }
    }


}

/// <reference path='./shaders/ProgressCircleShader.ts'/>

class ProgressCircle extends THREE.Mesh implements Cursable {

    public tweenVar:number;
    public geometry:THREE.Geometry;
    public material:THREE.ShaderMaterial;

    private camera:THREE.Camera;
    private backgroundCircle:ProgressCircle;

    constructor(camera:THREE.Camera,
                thickness:number = 0.15,
                radius:number = 0.4,
                resolution:number = 18,
                child?:boolean) {

        if(Main.mobile){
            radius = 1;            
        }
        const geo = new THREE.PlaneGeometry(1, thickness, resolution, 1);

        let material:THREE.ShaderMaterial;

        if (child) {
            let shader = new ProgressCircleShader(radius, thickness, true, new THREE.Vector4(1, 1, 1, 0.2));
            material = new THREE.ShaderMaterial(shader);
            material.transparent = true;
        } else {
            let shader = new ProgressCircleShader(radius, thickness);
            material = new THREE.ShaderMaterial(shader);
        }

        //noinspection TypeScriptValidateTypes
        super(geo, material);
        this.camera = camera;

        if (!child) {
            this.visible = false;
            this.backgroundCircle = new ProgressCircle(camera, thickness, radius, resolution, true);
            this.add(this.backgroundCircle);
            this.translateZ(0.1);
        }

        this.tweenVar = 0;
    }

    public updateProgress(prog:number):void {
        this.visible = true;
        this.material.uniforms.fProgress.value = prog;
    }

    public updatePosition(mouse:THREE.Vector3):void {
        mouse.unproject(this.camera);
        const dir = mouse.sub(this.camera.position).normalize();
        const dist = (-30 - this.camera.position.z) / dir.z;

        const var5 = this.camera.position.clone().add(dir.multiplyScalar(dist));

        this.position.x = var5.x;
        this.position.y = var5.y;
        this.position.z = var5.z;
    }

    public reset():void {
        this.visible = false;
    }
}
